import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import App from "./App";
import store from "./_App/Redux/store";
import reportWebVitals from "./reportWebVitals";
import i18next from "i18next";

//----------- IMPORT DES FICHIERS DE TRADUCTIONS --------------
import translationAR from "./locales/ar/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationEN from "./locales/en/translation.json";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    fr: { translation: translationFR },
    ar: { translation: translationAR },
  },
  lng: "fr", // Use the user's preference or fallback to a default
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
