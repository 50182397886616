import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import {
  UserSousSocietesType,
  UserType,
} from "../../../Types/Entites/UserType";
import _ from "lodash";

export const login = createAsyncThunk(
  "users/login",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post("users/login", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findUsers = createAsyncThunk(
  "users/findUsers",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get("users/findUsers");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findUserSoussociete = createAsyncThunk(
  "users/findUserSousSociete",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`users/findUserSousSociete/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findSousSocietes = createAsyncThunk(
  "users/findSousSocietes",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`users/findSousSocietes`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface auth {
  auth: { user: UserType; token: string } | null;
  users: UserType[];
  usersByCode: { [code: string]: UserType[] };
  langue: string;
  userSousSocietes: UserSousSocietesType[];
  currentSousSociete: string;
  soussocietes: any[];
}

const initialState: auth = {
  auth: null,
  users: [],
  langue: "fr",
  usersByCode: {},
  userSousSocietes: [],
  currentSousSociete: "",
  soussocietes: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserLang: (state, action) => {
      if (state.auth) state.langue = action.payload;
    },
    logout: (state) => {
      return {
        ...state,
        auth: initialState.auth,
        currentSousSociete: "",
      };
    },
    setCurrentSousSociete: (state, action) => {
      return {
        ...state,
        currentSousSociete: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      console.log(action.payload);
      let data = {
        user: action.payload.result,
        token: action.payload.token,
      };
      state.auth = data;
    });
    builder.addCase(findUsers.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload.data, "code");

      return {
        ...state,
        users: action.payload.data,
        usersByCode: groupedByCode,
      };
    });
    builder.addCase(findUserSoussociete.fulfilled, (state, action) => {
      const selectedSousSociete =
        state.currentSousSociete || // Keep the existing value if it's already set
        (state.auth?.user.role_code === "admin"
          ? "Tous"
          : action.payload.data[0]?.soussociete_code);

      return {
        ...state,
        userSousSocietes: action.payload.data,
        currentSousSociete: selectedSousSociete,
      };
    });
    builder.addCase(findSousSocietes.fulfilled, (state, action) => {
      return {
        ...state,
        soussocietes: action.payload.data,
      };
    });
  },
});
export const { logout, updateUserLang, setCurrentSousSociete } =
  authSlice.actions;

export const Auth = (state: RootState) => state.auth.auth;
export const OnlineUser = (state: RootState) => state.auth.auth?.user;

export const Langue = (state: RootState) => state.auth.langue;

export const currentUser = (state: RootState) => state.auth.auth?.user;
export const users = (state: RootState) => state.auth.users;
export const usersByCode = (state: RootState) => state.auth.usersByCode;
export const userSousSociete = (state: RootState) =>
  state.auth.userSousSocietes;
export const currentSousSociete = (state: RootState) =>
  state.auth.currentSousSociete;
export const soussocietes = (state: RootState) => state.auth.soussocietes;

export default authSlice.reducer;
