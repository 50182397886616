import moment from "moment";
import { useEffect, useState } from "react";
import DashboardCard from "../../Components/Dashboard/DashboardCard";
import { useTranslation } from "react-i18next";
import DAshboardTable from "../../Components/Dashboard/DashboardTable";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findChargeCamionsToday,
  findhistoriqueVehicule,
  findTop10Camions,
  findVehicules,
  findVehiculesNumberOfRepairs,
  findZones,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { findAssurances } from "../../_App/Redux/Slices/assurances/assurancesSlice";
import DataGridProDemo from "../../Components/Dashboard/DashboardCard";
import { findLastMonthCost } from "../../_App/Redux/Slices/entretiens/entretienSlice";
import { findVisiteTechniques } from "../../_App/Redux/Slices/visiteTech/visiteTechSlice";
import { findTaxes } from "../../_App/Redux/Slices/taxes/taxesSlice";
import {
  currentSousSociete,
  findSousSocietes,
  OnlineUser,
  soussocietes,
} from "../../_App/Redux/Slices/auth/authSlice";
import { getStats, stats } from "../../_App/Redux/Slices/stats/statsSlice";
import AnalysesCards from "../../Components/Dashboard/AnalysesCards";

interface DateTime {
  hours: number;
  minutes: number;
  seconds: number;
}

interface Details {
  chargesfixes: number;
  chargesvariables: number;
  camensuel: number;
  caannuel: number;
  km_m: number;
  km_a: number;
}

const Analyses: React.FC = () => {
  const date = new Date();
  const [load, setLoad] = useState(true);

  const [dateTime, setDateTime] = useState<DateTime>({
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
  });
  const [topcamions, settopcamions] = useState<any[]>([]);
  const [details, setdetails] = useState<Details>({
    chargesfixes: 0,
    chargesvariables: 0,
    camensuel: 0,
    caannuel: 0,
    km_m: 0,
    km_a: 0,
  });
  const [today, settoday] = useState<string>(moment().format("YYYY-MM-DD"));

  //------------- Translation ---------------
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const currentSousSte = useAppSelector(currentSousSociete);

  useEffect(() => {
    const fetchData = async () => {
      if (currentSousSte) {
        try {
          dispatch(findSousSocietes(null));
          dispatch(findhistoriqueVehicule(null));
        } catch (error) {
          console.error(error); // Handle any errors that occur during the asynchronous operations
        }
      }
    };

    fetchData(); // Call the async function

    const timer = setInterval(() => {
      const date = new Date();
      setDateTime({
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [dispatch, load, currentSousSte]);

  const onChangeDateFormat = () => {};

  return (
    <div className="page-content mt-0">
      <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
        <div>
          <h6 className="mb-3 mb-md-0">
            {t("aujourdhui")} {moment().format("dddd LL")} :{" "}
            <b className="text-primary">
              {dateTime.hours}:{dateTime.minutes}:{dateTime.seconds}{" "}
              <i className="fa fa-clock-o " />{" "}
            </b>
          </h6>
        </div>
      </div>
      <AnalysesCards /> {/* row */}
      <div className="row"></div> {/* row */}
    </div>
  );
};

export default Analyses;
