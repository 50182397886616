import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../_App/Redux/hooks";
import {
  currentSousSociete,
  currentUser,
  findUserSoussociete,
  Langue,
  logout,
  OnlineUser,
  setCurrentSousSociete,
  updateUserLang,
  userSousSociete,
} from "../_App/Redux/Slices/auth/authSlice";
import i18next from "i18next";
// import NotificationsMenu from "react-notifications-menu";
import {
  approveDemande,
  findDemandesEntretien,
  ListDemandesEntretien,
} from "../_App/Redux/Slices/entretiens/entretienSlice";
import {
  findVehicules,
  ListVehiculesParCode,
} from "../_App/Redux/Slices/vehicules/vehiculesSlice";
import Swal from "sweetalert2";

const Header = () => {
  //---------- History ----------
  // ---- outil de Traduction ----------
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // ----- Language global selectionné
  const language = useAppSelector(Langue);
  const userOnline = useAppSelector(OnlineUser);
  const [lg, setLg] = useState(
    language === "fr" ? "Français" : language === "ar" ? "Arabe" : "English"
  );
  {
    language === "ar"
      ? require("moment/locale/ar-tn")
      : language === "fr"
      ? require("moment/locale/fr")
      : require("moment/locale/en-au");
  }

  // USER INFORMATIONS
  const user = useAppSelector(currentUser);
  // SELECT CHANGE LANGUAGES
  const changeLanguage = (language: string) => {
    i18next.changeLanguage(language);
    if (language === "ar") {
      setLg("Arabe");
    } else if (language === "fr") {
      setLg("Français");
    } else if (language === "en") {
      setLg("English");
    }
    dispatch(updateUserLang(language));
  };

  const seDeconnecter = () => {
    dispatch(logout());
  };
  const sousSoucietes = useAppSelector(userSousSociete);
  const currentSousSte = useAppSelector(currentSousSociete);
  useEffect(() => {
    if (userOnline) {
      dispatch(findUserSoussociete(userOnline.code));
      dispatch(findDemandesEntretien(currentSousSte));
      dispatch(findVehicules(currentSousSte));
    }
  }, [dispatch]);

  const demandes = useAppSelector(ListDemandesEntretien);
  const vehicules = useAppSelector(ListVehiculesParCode);

  const approveDemandeModal = (item: any) => {
    Swal.fire({
      title: `Demande ${item.type}`,
      html: `
          <div>
            <p><strong>Chauffeur:</strong> ${item.user_code}</p>
            <p><strong>Description:</strong> ${item.objet}</p>
            <p><strong>Note:</strong> ${item.message}</p>
            <!-- Add more content as needed -->
          </div>
        `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, approuver !",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Approuvée !",
          text: "Demande a été approuvée.",
          icon: "success",
        });
        const res = dispatch(approveDemande(item.id));
      }
    });
  };

  return (
    <nav className="navbar">
      <a href="#" className="sidebar-toggler">
        <i data-feather="menu" />
      </a>
      <div className="navbar-content">
        {/* <form className="search-form">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i data-feather="search" />
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              id="navbarForm"
              placeholder="Search here..."
            />
          </div>
        </form> */}
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <select
              className="form-control  text-primary fs-12 "
              style={{ width: "100px" }}
              value={language}
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <option value="fr"> Français</option>
              <option value="ar"> العربية</option>
              <option value="en"> English </option>
            </select>
          </li>

          <li className="nav-item dropdown nav-profile">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="profileDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-bell"></i>{" "}
            </a>
            <div
              className="dropdown-menu"
              aria-labelledby="profileDropdown"
              style={{ width: "400px", maxWidth: "max-content" }}
            >
              <div className="dropdown-body">
                <ul className="profile-nav p-0 pt-3">
                  {demandes &&
                    demandes["0"]?.map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <li
                          className="nav-item notification-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => approveDemandeModal(item)}
                        >
                          <a className="nav-link">
                            <i className="fas fa-tools"></i>{" "}
                            <span
                              style={{
                                textTransform: "capitalize",
                                marginLeft: "10px",
                              }}
                            >
                              {" "}
                              Demande d'entretien de{" "}
                              {vehicules &&
                                vehicules[item.vehicule_code] &&
                                vehicules[item.vehicule_code][0].num_serie}
                            </span>
                            <span
                              style={{
                                textTransform: "capitalize",
                                marginLeft: "10px",
                                fontSize: "13px",
                                fontWeight: "500",
                              }}
                            >
                              {item.date}
                            </span>
                          </a>
                        </li>
                        {index + 1 < demandes.length ? <hr></hr> : null}
                      </React.Fragment>
                    ))}
                </ul>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown nav-profile">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="profileDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="../assets/images/users/2.jpg" alt="profile" />
            </a>
            <div className="dropdown-menu" aria-labelledby="profileDropdown">
              <div className="dropdown-header d-flex flex-column align-items-center">
                <div className="figure mb-3">
                  <img src="../assets/images/users/2.jpg" alt="user" />
                </div>
                <div className="info text-center">
                  <p className="name font-weight-bold mb-0"> {user?.nom}</p>
                  <p className="email text-muted mb-3"> {user?.email}</p>
                </div>
              </div>
              <div className="dropdown-body">
                <ul className="profile-nav p-0 pt-3">
                  {user?.role_code === "admin" ? (
                    <li className="nav-item" style={{ cursor: "pointer" }}>
                      <a
                        className={
                          "nav-link " +
                          (currentSousSte === "Tous" ? "active" : "")
                        }
                        onClick={() => dispatch(setCurrentSousSociete("Tous"))}
                      >
                        <i className="fas fa-building text-primary"></i>
                        <span
                          style={{
                            textTransform: "capitalize",
                            marginLeft: "10px",
                          }}
                        >
                          {"Tous "}
                        </span>
                      </a>
                    </li>
                  ) : null}
                  {sousSoucietes &&
                    sousSoucietes.map((item, index) => (
                      <li
                        className="nav-item"
                        key={index}
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className={
                            "nav-link " +
                            (item.soussociete_code === currentSousSte
                              ? "active"
                              : "")
                          }
                          onClick={() =>
                            dispatch(
                              setCurrentSousSociete(item.soussociete_code)
                            )
                          }
                        >
                          <i className="fas fa-building text-primary"></i>
                          <span
                            style={{
                              textTransform: "capitalize",
                              marginLeft: "10px",
                            }}
                          >
                            {item.nom}
                          </span>
                        </a>
                      </li>
                    ))}
                  <hr></hr>
                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a className="nav-link" onClick={() => seDeconnecter()}>
                      <i data-feather="log-out" />
                      <span>{t("sedeconnecter")}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
