import React from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import { EntretienType } from "../../_App/Types/Entites/EntretienType";
import {
  deleteEntretien,
  selectEntretiens,
} from "../../_App/Redux/Slices/entretiens/entretienSlice";
import Swal from "sweetalert2";
import { ListVehiculesParCode } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";

const EntretiensList = ({
  selectedEntretien,
  setSelectedEntretien,
  setShowModal,
  load,
  setLoad,
  searchTerm,
}: any) => {
  const dispatch = useAppDispatch();
  const entretiens = useAppSelector(selectEntretiens);
  const vehicules = useAppSelector(ListVehiculesParCode);

  const { t } = useTranslation();

  const deleteAnEntretien = (item: EntretienType) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Supprimé!",
          text: "Votre fichier a été supprimé.",
          icon: "success",
        });
        const res = dispatch(deleteEntretien(item.id));
        setLoad(!load);
      }
    });
  };
  const filteredList = entretiens.filter((assurance) => {
    const vehicule = vehicules[assurance.vehicule_code];
    const vehiculeMatches =
      vehicule &&
      (vehicule[0].marque + " " + vehicule[0].num_serie)
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

    const categorieMatches = assurance.type
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    return vehiculeMatches || categorieMatches;
  });

  const totalEntretiens = filteredList.reduce(
    (sum: number, item: any) => sum + item.cout_total,
    0
  );
  return (
    <div className="card-body">
      <table className="table table-bordered">
        <thead>
          <tr className="text-center">
            <th>{t("Vehicule")}</th>
            <th>{t("Date")}</th>
            <th>{t("Coût")}</th>
            <th>{t("Type")}</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length &&
            filteredList?.map((item: EntretienType, index: number) => (
              <tr
                key={index}
                className={
                  index % 2 === 1
                    ? "fs-12 bg-azure-lightest text-center"
                    : "fs-12 text-center"
                }
                onClick={() => setSelectedEntretien(item)}
              >
                <td>
                  <b className="text-muted">
                    {vehicules[item.vehicule_code] &&
                    vehicules[item.vehicule_code][0]
                      ? vehicules[item.vehicule_code][0].marque +
                        " " +
                        vehicules[item.vehicule_code][0].num_serie
                      : item.vehicule_code}
                  </b>
                </td>
                <td>{item.date}</td>
                <td>{item.cout_total.toFixed(3)}</td>
                <td>{item.type}</td>
                <td>
                  <button
                    className="btn btn-info btn-icon"
                    onClick={() => {
                      setSelectedEntretien(item);
                      setShowModal(true);
                    }}
                  >
                    <i className="fa fas fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-danger btn-icon"
                    onClick={() => {
                      deleteAnEntretien(item);
                    }}
                  >
                    <i className="fa fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr className="text-center">
            <td colSpan={2}>
              <b>{t("Coût total")}</b>
            </td>
            <td className="text-danger">
              <b> {totalEntretiens.toFixed(3)} </b>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default EntretiensList;
