import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Box } from "@mui/material";

export default function BasicPie({ data }: any) {
  return (
    <Box sx={{ width: 400, height: 300, position: "relative" }}>
      <PieChart
        series={[
          {
            data: data,
            innerRadius: 50,
            highlightScope: { faded: "global", highlighted: "item" },
          },
        ]}
        margin={{ right: 175 }}
        width={400}
        height={300}
      />
    </Box>
  );
}
