import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  Groups,
  saveChecklistItem,
} from "../../_App/Redux/Slices/checklists/checklistsSlice";
import { Modal } from "react-bootstrap";

interface ChecklistData {
  data: any;
  groupe: string;
  id_groupe: string | null;
  maintenance: string;
}
type ChecklistDataType = {
  libelle: String;
  ordre: number;
  obligation: number;
  type: string;
};

const ChecklistForm = ({ showModal, setShowModal }: any) => {
  const dispatch = useAppDispatch();
  const groups = useAppSelector(Groups);

  const [rows, setRows] = useState<ChecklistDataType[]>([]);
  const [groupe, setGroupe] = useState<any>();
  const [maintenance, setMaintenance] = useState<any>("Curatif");

  const closeModal = () => {
    setRows([]);
    setShowModal(false);
  };

  const addRow = (
    libelle: string,
    ordre: number,
    obligation: number,
    type: string
  ) => {
    const newRow = { libelle, ordre, obligation, type };
    setRows([...rows, newRow]);
  };

  const deleteRow = (indexToDelete: number) => {
    const updatedRows = rows.filter((_, index) => index !== indexToDelete);
    setRows(updatedRows);
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (groupe && rows.length) {
      let newData: ChecklistData = {
        data: rows,
        groupe: groupe,
        id_groupe: "1",
        maintenance: maintenance,
      };
      console.log(JSON.stringify(newData));
      try {
        const response = await dispatch(saveChecklistItem(newData));
        if (response && response.payload && response.payload.success) {
          console.log(response.payload);
          closeModal();
        } else {
          alert("Erreur s'est produite");
        }
      } catch (error) {
        console.error("Error during dispatch:", error);
      }
    }
  };

  return (
    <div>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        show={showModal}
        onHide={() => closeModal()}
      >
        <Modal.Header>
          <Modal.Title>
            <span className="modal-title" id="addCategorieLabel">
              {" "}
              Nouveau checklist
            </span>
          </Modal.Title>
          <button
            type="reset"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              closeModal();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <div className="searchable-container m-0">
          <div className="widget-content searchable-container list">
            <div className="searchable-items list">
              <form onSubmit={onSubmit}>
                <Modal.Body>
                  <div className="items items-header-section">
                    <div className="item-content">
                      <div className="col-2">
                        <h4 onClick={() => console.log(rows)}>Groupe</h4>
                      </div>

                      <div className="user-location col-10">
                        <div className="contact-phone">
                          <span className="validation-text">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Groupe"
                              value={groupe}
                              onChange={(e) => setGroupe(e.currentTarget.value)}
                            />
                          </span>
                        </div>
                      </div>
                      {/* <div className="user-location col-5">
                        <div className="contact-phone">
                          <span className="validation-text">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                setMaintenance(e.currentTarget.value)
                              }
                            >
                              <option value={""}>Maintenance</option>
                              <option value="Curatif">Curatif</option>{" "}
                              <option value="Préventif">Préventif</option>
                            </select>
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {rows.map((row: any, index: any) => {
                    return (
                      <div className="items" key={index}>
                        <div
                          className="item-content"
                          style={{
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="col-5">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Libelle"
                              value={row.libelle}
                              onChange={(e) => {
                                const updatedRows = [...rows];
                                updatedRows[index].libelle =
                                  e.currentTarget.value;
                                setRows(updatedRows);
                              }}
                            />
                          </div>
                          <div className="col-4">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                const updatedRows = [...rows];
                                updatedRows[index].type = e.currentTarget.value;
                                setRows(updatedRows);
                              }}
                            >
                              <option value={""}>Type</option>

                              <option value="Normal" key={index}>
                                Normal
                              </option>
                              <option value="Bloquant" key={index}>
                                Bloquant
                              </option>
                              <option value="Avec dérogation" key={index}>
                                Avec dérogation
                              </option>
                            </select>
                          </div>
                          <div className="col-1">
                            <input
                              style={{ padding: 0, textAlign: "center" }}
                              type="text"
                              className="form-control"
                              placeholder="ordre"
                              value={row.ordre}
                              onChange={(e) => {
                                const updatedRows = [...rows];
                                updatedRows[index].ordre = Number(
                                  e.currentTarget.value
                                );
                                setRows(updatedRows);
                              }}
                            />
                          </div>
                          <div
                            className="col-1"
                            style={{ display: "contents" }}
                          >
                            {/* <input
                              style={{ padding: 0, textAlign: "center" }}
                              type="text"
                              className="form-control"
                              placeholder="obligation"
                              value={row.obligation}
                              onChange={(e) => {
                                const updatedRows = [...rows];
                                updatedRows[index].obligation = Number(
                                  e.currentTarget.value
                                );
                                setRows(updatedRows);
                              }}
                            /> */}
                            <label
                              className="switch s-icons s-outline  s-outline-success"
                              // style={{ height: "unset" }}
                            >
                              <input
                                type="checkbox"
                                checked={row.obligation === 1}
                                onChange={(e) => {
                                  const updatedRows = [...rows];
                                  updatedRows[index].obligation = e
                                    .currentTarget.checked
                                    ? 1
                                    : 0;
                                  setRows(updatedRows);
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                          <div className="col-1">
                            <i
                              className="fas fa-times-circle"
                              style={{ color: "#e7515a" }}
                              onClick={() => deleteRow(index)}
                            ></i>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <button
                    className="btn btn-success"
                    style={{ width: "100%" }}
                    onClick={() => addRow("", 0, 0, "")}
                    type="button"
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-primary-app"
                    style={{ width: "100%" }}
                  >
                    Enregistrer
                  </button>
                </Modal.Footer>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChecklistForm;
