import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import { PieceRechangeType } from "../../../Types/Entites/PieceRechangeType";

interface StockUpdateData {
  id: string;
  quantity: number;
}
export const savePieceDeRechange = createAsyncThunk(
  "piecesRechange/createPieceRechange",
  async (data: PieceRechangeType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "piecesRechange/createPieceRechange",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const savePieceDeRechangeList= createAsyncThunk(
  "piecesRechange/createMultiplePieces",
  async (data: any[], { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "piecesRechange/createMultiplePieces",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findPiecesDeRechange = createAsyncThunk(
  "piecesRechange/findPiecesRechange",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `piecesRechange/findPiecesRechange/${id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updatePieceDeRechange = createAsyncThunk(
  "piecesRechange/updatePieceRechange",
  async ({ id, data }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(
        `piecesRechange/updatePieceRechange/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updatePieceDeRechangesStock = createAsyncThunk(
  "piecesRechange/updateStock",
  async (items: StockUpdateData[], { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`piecesRechange/updateStock`, items);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const deletePieceDeRechange = createAsyncThunk(
  "piecesRechange/deletePieceRechange",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `piecesRechange/deletePieceRechange/${id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface PiecesDeRechange {
  piecesDeRechangeList: PieceRechangeType[];
}

const initialState: PiecesDeRechange = {
  piecesDeRechangeList: [],
};

export const piecesDeRechangeSlice = createSlice({
  name: "piecesDeRechange",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(savePieceDeRechange.fulfilled, (state, action) => {
      let newPieceDeRechange = action.payload.data;
      state.piecesDeRechangeList.splice(0, 0, newPieceDeRechange);
    });

    builder.addCase(findPiecesDeRechange.fulfilled, (state, action) => {
      return {
        ...state,
        piecesDeRechangeList: action.payload.data,
      };
    });

    builder.addCase(updatePieceDeRechange.fulfilled, (state, action) => {
      const index = state.piecesDeRechangeList.findIndex(
        (piece) => piece.id === action.payload.data.id
      );
      if (index !== -1) {
        const updatedList = [...state.piecesDeRechangeList];
        updatedList[index] = {
          ...updatedList[index],
          ...action.payload.data,
        };
        return {
          ...state,
          piecesDeRechangeList: updatedList,
        };
      }
      return state;
    });
  },
});

export const ListPiecesDeRechange = (state: RootState) =>
  state.piecesDeRechange.piecesDeRechangeList;

export default piecesDeRechangeSlice.reducer;
