import { thunk } from "redux-thunk";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authSlice from "./Slices/auth/authSlice";
import vehiculeSlice from "./Slices/vehicules/vehiculesSlice";
import assurancesSlice from "./Slices/assurances/assurancesSlice";
import visiteTechSlice from "./Slices/visiteTech/visiteTechSlice";
import piecesDeRechangeSlice from "./Slices/piecesRechange/piecesRechangeSlice";
import entretiensSlice from "./Slices/entretiens/entretienSlice";
import checklistsSlice from "./Slices/checklists/checklistsSlice";
import taxesSlice from "./Slices/taxes/taxesSlice";
import payHistorySlice from "./Slices/payHistory/payHistorySlice";
import statsSlice from "./Slices/stats/statsSlice";
import userSlice from "./Slices/users/userSlice";

const persistConfig = {
  key: "nomatruck",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: authSlice,
  vehicules: vehiculeSlice,
  assurances: assurancesSlice,
  visiteTechnique: visiteTechSlice,
  piecesDeRechange: piecesDeRechangeSlice,
  entretiens: entretiensSlice,
  checklist: checklistsSlice,
  taxes: taxesSlice,
  payHistory: payHistorySlice,
  stats: statsSlice,
  users: userSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV === "developpement",
  //middleware: [applyMiddleware(thunk), curryGetDefaultMiddleware()]
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
