import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../_App/Redux/hooks";
import { findTaxes } from "../_App/Redux/Slices/taxes/taxesSlice";
import { findVehicules } from "../_App/Redux/Slices/vehicules/vehiculesSlice";
import TaxesList from "../Components/Taxes/TaxesList";
import TaxeForm from "../Components/Taxes/TaxeForm";
import {
  currentSousSociete,
  OnlineUser,
} from "../_App/Redux/Slices/auth/authSlice";

const Taxes = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedTaxe, setSelectedTaxe] = useState(undefined);
  const [load, setLoad] = useState(false);
  const currentSousSte = useAppSelector(currentSousSociete);
  const [searchTerm, setSearchTerm] = useState(""); // Add state for search term

  useEffect(() => {
    if (currentSousSte) {
      dispatch(findTaxes(currentSousSte));
      dispatch(findVehicules(currentSousSte));
    }
  }, [dispatch, load, currentSousSte]);

  return (
    <div>
      <nav className="navbar search-bar">
        <a href="#" className="sidebar-toggler">
          <i data-feather="menu" />
        </a>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search" />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                id="navbarForm"
                placeholder="Chercher ..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
              />
            </div>
          </form>
        </div>
      </nav>
      <nav className="page-breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Taxes</a>
          </li>
        </ol>
      </nav>
      <div className="row content-card">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <i className="mdi mdi-tune-vertical text-primary " /> Liste des
              Taxes{" "}
              <button
                className="btn btn-primary float-right"
                onClick={() => setShowModal(true)}
              >
                +
              </button>
            </div>
            <TaxesList
              searchTerm={searchTerm} // Pass search term as a prop
              selectedTaxe={selectedTaxe}
              setSelectedTaxe={setSelectedTaxe}
              setShowModal={setShowModal}
              load={load}
              setLoad={setLoad}
            />
            <TaxeForm
              showModal={showModal}
              setShowModal={setShowModal}
              selectedTaxe={selectedTaxe}
              setSelectedTaxe={setSelectedTaxe}
              load={load}
              setLoad={setLoad}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Taxes;
