import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../_App/Redux/hooks";
import { findAllChecklists } from "../_App/Redux/Slices/checklists/checklistsSlice";
import Checklists from "../Components/Checklists/Checklists";
import ChecklistForm from "../Components/Checklists/ChecklistForm";
import ListChecklistsCuratif from "../Components/Checklists/Result";
import {
  findVehicules,
  ListVehiculesParCode,
} from "../_App/Redux/Slices/vehicules/vehiculesSlice";
import { useParams } from "react-router-dom";
import { currentSousSociete, OnlineUser } from "../_App/Redux/Slices/auth/authSlice";

const ChecklistsResultPage = () => {
  const { vehiculeCode, date } = useParams();

  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [load, setLoad] = useState(false);
  const currentSousSte = useAppSelector(currentSousSociete);

  useEffect(() => {
    dispatch(findAllChecklists(null));
    if (currentSousSte) dispatch(findVehicules(currentSousSte));
  }, [dispatch, load]);
  const vehicules = useAppSelector(ListVehiculesParCode);

  return (
    <div>
      {vehiculeCode && vehicules[vehiculeCode] && vehicules[vehiculeCode][0] ? (
        <>
          <nav className="page-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">
                  {"Resultat de checklist Vehicule " +
                    vehicules[vehiculeCode][0].marque +
                    " " +
                    vehicules[vehiculeCode][0].num_serie}
                </a>
              </li>
            </ol>
          </nav>
          <div className="row content-card">
            <div className="col-12">
              <div className="card">
                <ListChecklistsCuratif />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ChecklistsResultPage;
