import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { currentSousSociete } from "../../_App/Redux/Slices/auth/authSlice";
import {
  findhistoriqueVehicule,
  HistoriqueVehiculesParVehicule,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { useParams } from "react-router-dom";
import {
  findPayHistory,
  ListPayHistoryByCode,
} from "../../_App/Redux/Slices/payHistory/payHistorySlice";

interface HistoryItem {
  date: string;
  payment?: string;
  status?: string;
}

interface PaymentItem {
  type: string;
  cout: number;
}

const VehiculeHistory: React.FC = () => {
  const { vehicule } = useParams<{ vehicule: string }>();
  const dispatch = useAppDispatch();
  const soussociete = useAppSelector(currentSousSociete);
  const history = useAppSelector(HistoriqueVehiculesParVehicule);
  const payments = useAppSelector(ListPayHistoryByCode);

  useEffect(() => {
    if (soussociete && vehicule) {
      dispatch(findhistoriqueVehicule(null));
      dispatch(findPayHistory(soussociete));
    }
  }, [dispatch, soussociete, vehicule]);

  const sortedHistory = vehicule && history[vehicule]
    ?.slice()
    .sort((a: HistoryItem, b: HistoryItem) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime(); // Sort descending by date
    });

  return (
    <div>
      <nav className="navbar search-bar">
        <a href="#" className="sidebar-toggler">
          <i data-feather="menu" />
        </a>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search" />
                </div>
              </div>
              {/* Uncomment and implement search functionality if needed */}
              {/* <input
                type="text"
                className="form-control"
                id="navbarForm"
                placeholder="Chercher ..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              /> */}
            </div>
          </form>
        </div>
      </nav>
      <nav className="page-breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Vehicules</a>
          </li>
        </ol>
      </nav>
      <div className="row content-card">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <i className="mdi mdi-tune-vertical text-primary" /> Historique
              vehicule
            </div>
            <div className="card-body">
              <div>
                {vehicule && (
                  <ul className="timeline">
                    {sortedHistory?.map((item: HistoryItem, index: number) => (
                      <li className="event" data-date={item.date} key={index}>
                        <h6>
                          {item.payment && payments[item.payment]?.[0]
                            ? `${payments[item.payment][0].type} : ${
                                payments[item.payment][0].cout
                              } TND`
                            : item.status}
                        </h6>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehiculeHistory;
