import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ListPiecesDeRechange } from "../../_App/Redux/Slices/piecesRechange/piecesRechangeSlice";
import {
  deleteEntretienPiece,
  entretienPieces,
} from "../../_App/Redux/Slices/entretiens/entretienSlice";
import Swal from "sweetalert2";

type Data = {
  id: number | null;
  piece: string;
  quantity: number;
  prix: number;
  stock?: number; // Add stock field
};

interface AffectationPieceFormProps {
  setData: (data: Data[]) => void;
  entretien: number | null;
  onTotalPriceChange: (totalPrice: number) => void;
}

const AffectationPieceForm: React.FC<AffectationPieceFormProps> = ({
  setData,
  entretien,
  onTotalPriceChange,
}) => {
  const dispatch = useAppDispatch();
  const pieces = useAppSelector(ListPiecesDeRechange);
  const entrtienPieces = useAppSelector(entretienPieces);
  const [rows, setRows] = useState<Data[]>([]);

  const addRow = () => {
    const newRow = { piece: "", quantity: 1, id: null, prix: 0, stock: 0 };
    setRows([...rows, newRow]);
  };

  const deleteRow = (indexToDelete: number, id: number | null) => {
    if (entretien && id !== null) {
      Swal.fire({
        title: "Êtes-vous sûr?",
        text: "Vous ne pourrez pas revenir en arrière!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimez-le!",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteEntretienPiece(id));
          const updatedRows = rows.filter(
            (_, index) => index !== indexToDelete
          );
          setRows(updatedRows);
          Swal.fire("Supprimé!", "Votre fichier a été supprimé.", "success");
        }
      });
    } else {
      const updatedRows = rows.filter((_, index) => index !== indexToDelete);
      setRows(updatedRows);
    }
  };

  const updateRow = (index: number, updatedRow: Partial<Data>) => {
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], ...updatedRow };
    setRows(updatedRows);
  };

  useEffect(() => {
    if (entretien && entrtienPieces[entretien]) {
      const aux = entrtienPieces[entretien].map((item) => ({
        piece: item.piece_id,
        quantity: item.quantity,
        id: item.id,
        prix: item.prix,
      }));
      setRows(aux);
    }
  }, [entretien, entrtienPieces]);

  useEffect(() => {
    setData(rows);
    // const totalPrice = rows.reduce(
    //   (sum, row) => sum + row.prix * row.quantity,
    //   0
    // );
    // onTotalPriceChange(totalPrice);
  }, [rows, setData]);

  return (
    <div>
      <div className="searchable-container m-0">
        <div className="widget-content searchable-container list">
          <div className="searchable-items list">
            <h6 className="mb-3">Pièces de rechange internes</h6>
            <form>
              {rows.length > 0 && (
                <div className="items">
                  <div
                    className="item-content"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <div className="col-6">
                      <b>Pièce</b>
                    </div>
                    <div className="col-3">
                      <b>Prix</b>
                    </div>
                    <div className="col-2">
                      <b>Qté</b>
                    </div>
                    <div className="col-1 piecess"></div>
                  </div>
                </div>
              )}

              {rows.map((row, index) => (
                <div className="items" key={index}>
                  <div
                    className="item-content"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <div className="col-6">
                      <select
                        className="form-control"
                        value={row.piece}
                        onChange={(e) => {
                          const selectedPieceId = e.currentTarget.value;
                          const selectedPiece = pieces.find(
                            (piece) => piece.code === selectedPieceId
                          );
                          updateRow(index, {
                            piece: selectedPieceId,
                            prix: selectedPiece ? selectedPiece.prix_TTC : 0,
                            stock: selectedPiece ? selectedPiece.stock : 0,
                          });
                        }}
                      >
                        <option value="">Pièces de rechange</option>
                        {pieces
                          .filter((item) => item.stock > 0)
                          .map((item, i) => (
                            <option value={item.code} key={i}>
                              {item.libelle}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-3">
                      <input
                        style={{ padding: 0, textAlign: "center" }}
                        type="text"
                        className="form-control"
                        placeholder="Prix"
                        value={row.prix * row.quantity}
                        onChange={(e) =>
                          updateRow(index, {
                            prix: Number(e.currentTarget.value),
                          })
                        }
                      />
                    </div>
                    <div className="col-2">
                      <input
                        style={{ padding: 0, textAlign: "center" }}
                        type="number"
                        className="form-control"
                        placeholder="Quantité"
                        value={row.quantity}
                        onChange={(e) => {
                          const quantity = Number(e.currentTarget.value);
                          const stock = row.stock || 0;
                          if (quantity <= stock) {
                            updateRow(index, { quantity });
                          } else {
                            toast.error(
                              `La quantité ne peut pas dépasser le stock de ${stock}`
                            );
                            updateRow(index, { quantity: stock });
                          }
                        }}
                      />
                    </div>
                    <div className="col-1 piecess">
                      <i
                        className="fas fa-times-circle"
                        style={{ color: "#e7515a" }}
                        onClick={() => deleteRow(index, row.id)}
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
              <button
                className="btn btn-success mb-3"
                style={{ width: "100%" }}
                onClick={addRow}
                type="button"
              >
                <i className="fas fa-plus"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffectationPieceForm;
