import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import { AssuranceType } from "../../../Types/Entites/AssuranceType";
import { filterObjectsWithDate30DaysLater } from "../../../Helpers/helpers";

export const saveAssurance = createAsyncThunk(
  "assurances/createAssurance",
  async (data: AssuranceType, { rejectWithValue }) => {
    try {
      const response = await axios.post("assurances/createAssurance", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findAssurances = createAsyncThunk(
  "assurances/findAssurances",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`assurances/findAssurances/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateAssurance = createAsyncThunk(
  "assurances/updateAssurance",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `assurances/updateAssurance/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteAssurance = createAsyncThunk(
  "assurances/deleteAssurance",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`assurances/deleteAssurance/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Assurances {
  assuranceList: AssuranceType[];
  assuranceByVehicule: { [code: string]: AssuranceType[] };
  monthAwayAssuranceByVehicule: { [code: string]: AssuranceType[] };
}

const initialState: Assurances = {
  assuranceList: [],
  assuranceByVehicule: {},
  monthAwayAssuranceByVehicule: {},
};

export const assuranceSlice = createSlice({
  name: "assurances",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveAssurance.fulfilled, (state, action) => {
      let newAssurance = action.payload.data;
      state.assuranceList.splice(0, 0, newAssurance);
    });

    builder.addCase(findAssurances.fulfilled, (state, action) => {
      const filteredAssurance = filterObjectsWithDate30DaysLater(
        action.payload.data
      );

      const groupedByVehicule = _.groupBy(action.payload.data, "vehicule_code");
      const monthAwaygroupedByVehicule = _.groupBy(
        filteredAssurance,
        "vehicule_code"
      );
      return {
        ...state,
        assuranceList: action.payload.data,
        assuranceByVehicule: groupedByVehicule,
        monthAwayAssuranceByVehicule: monthAwaygroupedByVehicule,
      };
    });
    builder.addCase(deleteAssurance.fulfilled, (state, action) => {});

    builder.addCase(updateAssurance.fulfilled, (state, action) => {
      const index = _.findIndex(state.assuranceList, {
        id: action.payload.data.id,
      });
      if (index !== -1) {
        const updatedList = [...state.assuranceList];
        updatedList[index] = {
          ...updatedList[index],
        };
        return {
          ...state,
          assuranceList: updatedList,
        };
      }

      return state;
    });
  },
});

export const ListAssurances = (state: RootState) =>
  state.assurances.assuranceList;
export const AssuranceParVehicule = (state: RootState) =>
  state.assurances.assuranceByVehicule;
export const MonthAwayAssuranceByVehicule = (state: RootState) =>
  state.assurances.monthAwayAssuranceByVehicule;
export default assuranceSlice.reducer;
