import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../_App/Redux/hooks";

import { findVehicules, findZones } from "../_App/Redux/Slices/vehicules/vehiculesSlice";
import {
  findEntretienPieces,
  findEntretiens,
} from "../_App/Redux/Slices/entretiens/entretienSlice";
import EntretiensList from "../Components/Entretien/EntretiensList";
import EntretienForm from "../Components/Entretien/EntretienForm";
import { findPiecesDeRechange } from "../_App/Redux/Slices/piecesRechange/piecesRechangeSlice";
import {
  currentSousSociete,
  OnlineUser,
} from "../_App/Redux/Slices/auth/authSlice";
import ListPayHistory from "../Components/PayHistory/ListPayHistory";
import { findPayHistory } from "../_App/Redux/Slices/payHistory/payHistorySlice";

const History = () => {
  const dispatch = useAppDispatch();
  const [load, setLoad] = useState(false);
  const currentSousSte = useAppSelector(currentSousSociete);
  const [searchTerm, setSearchTerm] = useState(""); // Add state for search term

  useEffect(() => {
    if (currentSousSte) {
      dispatch(findPayHistory(currentSousSte));
      dispatch(findVehicules(currentSousSte));
      dispatch(findZones(null))
    }
  }, [dispatch, load, currentSousSte]);

  return (
    <div>
      <nav className="navbar search-bar">
        <a href="#" className="sidebar-toggler">
          <i data-feather="menu" />
        </a>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search" />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                id="navbarForm"
                placeholder="Chercher ..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
              />
            </div>
          </form>
        </div>
      </nav>
      <nav className="page-breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Historique de paiement</a>
          </li>
        </ol>
      </nav>
      <div className="row content-card">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <i className="mdi mdi-tune-vertical text-primary " /> Liste des
              Paiement{" "}
            </div>
            <ListPayHistory
              searchTerm={searchTerm} // Pass search term as a prop
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
