import React from "react";

const SideBar = () => {
  return (
    <nav className="sidebar">
      <div className="sidebar-header">
        <a href="#" className="sidebar-brand">
          Noma<span>Truck</span>
        </a>
        <div className="sidebar-toggler not-active">
          <span />
          <span />
          <span />
        </div>
      </div>
      <div className="sidebar-body">
        <ul className="nav">
          <li className="nav-item nav-category">Main</li>
          <li className="nav-item">
            <a href="/" className="nav-link">
              <i className="link-icon text-primary" data-feather="layout" />
              <span className="link-title">Tableau de bord</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/analyses" className="nav-link">
              <i className="fas fa-chart-line text-primary"></i>{" "}
              <span className="link-title" style={{ marginLeft: "15px" }}>Analyses</span>
            </a>
          </li>
          <li className="nav-item nav-category">Gestion</li>
          {/* <li className="nav-item">
          <a href="/vehicules" className="nav-link">
            <i class="fa fa-solid fa-car text-primary"></i>{" "}
            <span className="link-title" style={{ marginLeft: "14px" }}>
              Gestion Vehicules
            </span>
          </a>
        </li> */}
          <li className="nav-item">
            <a href="/vehicules" className="nav-link">
              <i className="fa fas fa-truck text-primary"></i>
              <span className="link-title" style={{ marginLeft: "10px" }}>
                Vehicules
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/assurances" className="nav-link">
              <i className="fa fa-solid fa-shield text-primary"></i>
              <span className="link-title" style={{ marginLeft: "14px" }}>
                Assurances
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/visitesTech" className="nav-link">
              <i className="fa fas fa-tasks text-primary"></i>
              <span className="link-title" style={{ marginLeft: "15px" }}>
                Visites Techniques
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/entretiens" className="nav-link">
              <i className="fa fas fa-wrench text-primary"></i>
              <span className="link-title" style={{ marginLeft: "15px" }}>
                Entretiens
              </span>
            </a>
          </li>{" "}
          <li className="nav-item">
            <a href="/piecesRechange" className="nav-link">
              <i className="fa fas fa-cogs text-primary"></i>
              <span className="link-title" style={{ marginLeft: "11px" }}>
                Pieces de rechange
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/taxes" className="nav-link">
              <i className="fas fa-file-invoice-dollar text-primary"></i>
              <span className="link-title" style={{ marginLeft: "17px" }}>
                Taxes
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/checklists" className="nav-link">
              <i className="far fa-calendar-check text-primary"></i>{" "}
              <span className="link-title" style={{ marginLeft: "15px" }}>
                Checklists
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/users" className="nav-link">
              <i className="fas fa-users text-primary"></i>{" "}
              <span className="link-title" style={{ marginLeft: "9px" }}>
                Personels
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/demandes" className="nav-link">
              <i className="far fa-comments text-primary"></i>{" "}
              <span className="link-title" style={{ marginLeft: "9px" }}>
                Demandes
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/history" className="nav-link">
              <i className="fas fa-history text-primary"></i>{" "}
              <span className="link-title" style={{ marginLeft: "12px" }}>
                Historique de paiement
              </span>
            </a>
          </li>
          {/*      <li className="nav-item">
                      <a href="/ajouterCamion" className="nav-link">
                      <i className="link-icon text-primary" data-feather="plus-square" />                            
                      <span className="link-title" style={{ marginLeft: "10px" }}>Ajouter Camion</span>
                      </a>
                  </li> */}
          {/* <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#parametrageCamion"
              role="button"
              aria-expanded="false"
              aria-controls="parametrageCamion"
            >
              <i className="link-icon text-primary" data-feather="settings" />
              <span className="link-title" style={{ marginLeft: "10px" }}>Paramétrage Camion</span>
              <i className="link-arrow " data-feather="chevron-down" />
            </a>
            <div className="collapse" id="parametrageCamion">
              <ul className="nav sub-menu">
                <li className="nav-item">
                  <a href="/parametrageEnteteCharge" className="nav-link">
                    Entête Charge
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/parametrageType" className="nav-link">
                    Type
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/parametrageLigneCharge" className="nav-link">
                    Ligne Charge
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#parametrageVehicule"
              role="button"
              aria-expanded="false"
              aria-controls="parametrageVehicule"
            >
              <i className="link-icon text-primary" data-feather="settings" />
              <span className="link-title" style={{ marginLeft: "10px" }}>Paramétrage Vehicule</span>
              <i className="link-arrow " data-feather="chevron-down" />
            </a>
            <div className="collapse" id="parametrageVehicule">
              <ul className="nav sub-menu">
                <li className="nav-item">
                  <a href="/vehicules" className="nav-link">
                    Vehicule
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/assurances" className="nav-link">
                    Assurance
                  </a>
                </li>
              </ul>
            </div>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default SideBar;
