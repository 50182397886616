import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import { numberWithSpaces } from "../../_App/Helpers/helpers";
import {
  deleteAssurance,
  ListAssurances,
} from "../../_App/Redux/Slices/assurances/assurancesSlice";
import { AssuranceType } from "../../_App/Types/Entites/AssuranceType";
import Swal from "sweetalert2";
import { ListVehiculesParCode } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import moment from "moment";

const AssurancesList = ({
  selectedAssurance,
  setSelectedAssurance,
  setShowModal,
  load,
  setLoad,
  searchTerm,
}: any) => {
  const dispatch = useAppDispatch();
  const assurances = useAppSelector(ListAssurances);
  const vehicules = useAppSelector(ListVehiculesParCode);

  const deleteAnAssurance = (item: AssuranceType) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Supprimé!",
          text: "Votre fichier a été supprimé.",
          icon: "success",
        });
        const res = dispatch(deleteAssurance(item.id));
        setLoad(!load);
      }
    });
  };
  const [startDate, setStartDate] = useState<any>(moment().startOf("day")); // Default to start of today
  const [endDate, setEndDate] = useState<any>(moment().endOf("day")); // Default to end of today

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(moment(e.target.value, "YYYY-MM-DD").startOf("day"));
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(moment(e.target.value, "YYYY-MM-DD").endOf("day"));
  };
  // Filter vehicules based on the search term
  const filteredList = assurances.filter((assurance) => {
    const vehicule = vehicules[assurance.vehicule_code];
    const vehiculeMatches =
      vehicule &&
      (vehicule[0].marque + " " + vehicule[0].num_serie)
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

    const categorieMatches = assurance.categorie
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    const dateMatches =
      moment(assurance.date).isSameOrAfter(startDate) &&
      moment(assurance.date).isSameOrBefore(endDate);

    return vehiculeMatches || categorieMatches || dateMatches;
  });

  const { t } = useTranslation();

  const totalTTC = filteredList.reduce(
    (sum: number, item) => sum + item.montant_TTC,
    0
  );
  const totalHT = filteredList.reduce(
    (sum: number, item) => sum + item.montant_HT,
    0
  );

  return (
    <div className="card-body">
      {/* <div className="date-filter" style={{ display: "flex", float: "right" }}>
        <div
          className="pb-2 mr-5"
          style={{ display: "flex", alignItems: "center" }}
        >
          <label htmlFor="start-date"></label>
          <input
            className="date-input"
            type="date"
            id="start-date"
            value={startDate.format("YYYY-MM-DD")}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="pb-2" style={{ display: "flex", alignItems: "center" }}>
          <label htmlFor="end-date"></label>
          <input
            className="date-input"
            type="date"
            id="end-date"
            value={endDate.format("YYYY-MM-DD")}
            onChange={handleEndDateChange}
          />
        </div>
      </div> */}

      <table className="table table-bordered ">
        <thead>
          <tr className="text-center">
            <th>{t("Vehicule")}</th>
            <th>{t("contrat")}</th>
            <th>{t("categorie")}</th>
            <th>{t("date debut")}</th>
            <th>{t("date fin")}</th>
            <th>{t("montant ttc")}</th>
            <th>{t("montant ht")}</th>
            <th>{t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length &&
            filteredList?.map((item, keys) => (
              <tr
                key={keys}
                className={
                  keys % 2 === 1
                    ? "fs-12 bg-azure-lightest text-center"
                    : "fs-12 text-center"
                }
                onClick={() =>
                  console.log(
                    moment(item?.date).isSameOrAfter(startDate, "day")
                  )
                }
              >
                <td>
                  <b className="text-muted">
                    {vehicules[item.vehicule_code] &&
                    vehicules[item.vehicule_code][0]
                      ? vehicules[item.vehicule_code][0].marque +
                        " " +
                        vehicules[item.vehicule_code][0].num_serie
                      : item.vehicule_code}
                  </b>
                </td>
                <td>{item.num_contrat}</td>
                <td>{item.categorie}</td>
                <td>{item.date}</td>
                <td>{item.date_fin}</td>
                <td>{item.montant_TTC.toFixed(3)}</td>
                <td>{item.montant_HT.toFixed(3)}</td>

                <td>
                  <button
                    className="btn btn-info btn-icon"
                    onClick={() => {
                      setSelectedAssurance(item);
                      setShowModal(true);
                    }}
                  >
                    <i className="fa fas fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-danger btn-icon"
                    onClick={() => {
                      deleteAnAssurance(item);
                    }}
                  >
                    <i className="fa fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr className="text-center">
            <td colSpan={5}>
              <b>{t("Coût total")}</b>
            </td>
            <td className="text-danger">
              <b> {totalTTC.toFixed(3)} </b>
            </td>
            <td className="text-danger">
              <b> {totalHT.toFixed(3)} </b>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default AssurancesList;
