import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findVehiculesAffectationsHistory,
  VehiculeHistory,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { usersByCode } from "../../_App/Redux/Slices/auth/authSlice";

const VehiculeUserHistoryModal = ({
  showModal,
  setShowModal,
  vehicule,
  setVehicule,
}: any) => {
  const dispatch = useAppDispatch();
  const history = useAppSelector(VehiculeHistory);
  const users = useAppSelector(usersByCode);

  useEffect(() => {
    if (vehicule) dispatch(findVehiculesAffectationsHistory(vehicule));
  }, [dispatch, vehicule]);
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title" id="addCategorieLabel">
            {" "}
            Historique Vehicule
            {}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            setVehicule(undefined);
            setShowModal(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ul className="timeline">
          {history &&
            users &&
            history?.map((item, index) =>
              item.user_id && users[item.user_id] ? (
                <li className="event" data-date={users[item.user_id][0].nom}>
                  <h6>{item.date_debut + " - " + item.date_fin} </h6>
                </li>
              ) : null
            )}
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default VehiculeUserHistoryModal;
