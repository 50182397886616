import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  affectationChecklistByMachineGroup,
  ChecklistResults,
  checklistsCuratifGroupedByGroupe,
  findAffectationChecklist,
  findAllChecklists,
  findChecklistResult,
  findChecklistResultByDate,
  findResultsImages,
  ResultsImages,
} from "../../_App/Redux/Slices/checklists/checklistsSlice";
import { ListVehiculesParCode } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import ZoomableImage from "./ZoomableImage";

const ListChecklistsCuratif = () => {
  const { vehiculeCode, date } = useParams();

  function extractTime(timestamp: any) {
    const date = new Date(timestamp);

    // Extracting time components
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Formatting time as string
    const time = `${hours}:${minutes}:${seconds}`;

    return time;
  }

  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();
  const vehicules = useAppSelector(ListVehiculesParCode);

  const checklists = useAppSelector(checklistsCuratifGroupedByGroupe);
  const results = useAppSelector(ChecklistResults);
  const images = useAppSelector(ResultsImages);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (vehiculeCode && date) {
          setLoading(true);
          dispatch(findAffectationChecklist(null));
          dispatch(findAllChecklists(null));
          dispatch(findResultsImages(null));
          dispatch(
            findChecklistResultByDate({
              vehiculeCode: vehiculeCode,
              date: date,
            })
          );

          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, vehiculeCode]);

  return (
    <div>
      {!loading && vehiculeCode && Object.keys(checklists).length > 0 ? (
        <div
          id="tableFooter"
          className="col-lg-12 col-12 layout-spacing"
          style={{ padding: "10px" }}
        >
          <div className="statbox widget box box-shadow">
            {/* <div className="widget-header">
              <div className="row">
                <div
                  className="col-xl-12 col-md-12 col-sm-12 col-12"
                  style={{ padding: "0px 4px" }}
                >
                  <table className="table table-bordered table-hover table-condensed mb-0 mt-0">
                    <thead>
                      <tr className="checklist-entete">
                        <th>
                          <img
                            style={{ height: "60px" }}
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/assets/img/ecopack_logo.png"
                            }
                            alt=""
                          />
                        </th>
                        <th className="text-center">
                          {" "}
                          {"Checklist demarrage ODP - MK"}{" "}
                        </th>
                        <th>-</th>
                      </tr>
                      <tr
                        className="checklist-entete"
                        style={{ borderTop: "1px solid #000" }}
                      >
                        <th>
                          Date : {horaires().date.toString().split("T")[0]}{" "}
                        </th>
                        <th>Heure : {horaires().heure.toString()}</th>
                        <th>N° ODP : {vehiculeCode}</th>
                        <th>Article : {""} </th>
                        <th>MK : </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>{" "} */}
            <div
              className="widget-content widget-content-area"
              style={{ padding: 0 }}
            >
              <div className="table-responsive">
                <table className="table table-bordered table-hover table-condensed mb-0 mt-0">
                  {vehiculeCode &&
                    Object.keys(checklists).length > 0 &&
                    Object.keys(checklists).map((key, index1) => (
                      <React.Fragment key={index1}>
                        <thead>
                          <tr>
                            <th colSpan={3} style={{ textAlign: "center" }}>
                              {" "}
                              {key}{" "}
                            </th>
                            <th className="text-center">Resultat</th>
                            <th className="text-center">
                              <i className="far fa-image"></i>
                            </th>

                            <th className="text-center">Heure</th>
                            <th>Commentaire</th>
                          </tr>
                        </thead>

                        <tbody>
                          {checklists[key].map((item: any, index) => (
                            <tr key={index}>
                              <td onClick={() => console.log(item)}>
                                {" "}
                                {index + 1}{" "}
                              </td>
                              <td
                                colSpan={2}
                                style={{
                                  maxWidth: "300px",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {" "}
                                {item.libelle}{" "}
                              </td>
                              <td className="text-center">
                                <ul className="table-controls">
                                  {results &&
                                  results[item.code] &&
                                  results[item.code][0] ? (
                                    <div
                                      className="badge"
                                      style={{
                                        background:
                                          results[item.code][0].result === "0"
                                            ? "#e60039"
                                            : "#7bd0a0",
                                        color: "#fff",
                                        textTransform: "capitalize",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {results[item.code][0].result === "0"
                                        ? "Non"
                                        : "Oui"}
                                    </div>
                                  ) : null}
                                </ul>
                              </td>
                              <td
                                className="text-center"
                                onClick={() => console.log()}
                              >
                                {results &&
                                results[item.code] &&
                                results[item.code][0] &&
                                images[results[item.code][0].code_result] ? (
                                  <ZoomableImage
                                    size={45}
                                    imageUrl={
                                      images[
                                        results[item.code][0].code_result
                                      ][0].path
                                    }
                                  />
                                ) : (
                                  <i className="far fa-image"></i>
                                )}

                                {/* {" "}
                                {results &&
                                results[item.code] &&
                                results[item.code][0] &&
                                images[results[item.code][0].code_result] ? (
                                  <img
                                    src={images[results[item.code][0].code_result][0].path}
                                    alt=""
                                  />
                                ) : (
                                  <i className="far fa-image"></i>
                                )} */}
                              </td>
                              <td className="text-center">
                                {" "}
                                {results &&
                                results[item.code] &&
                                results[item.code][0]
                                  ? results[item.code][0].heure_debut
                                  : ""}{" "}
                              </td>
                              <td>
                                {" "}
                                {results &&
                                results[item.code] &&
                                results[item.code][0]
                                  ? results[item.code][0].commentaire
                                  : ""}{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </React.Fragment>
                    ))}
                  {/* <tfoot>
                    <tr>
                      <th colSpan={2}>Heure de démarrage</th>
                      <th colSpan={2}>Rebut / Démarrage / Maintenance </th>
                      <th colSpan={2}>Rebut / Démarrage / Production</th>
                      <th>Team Leader</th>
                      <th>Contrôleur qualité</th>
                    </tr>
                    <tr>
                      <th
                        colSpan={2}
                        style={{ color: "#fff", border: "1px solid #000" }}
                      >
                        ............
                      </th>
                      <th
                        colSpan={2}
                        style={{ color: "#fff", border: "1px solid #000" }}
                      >
                        ............
                      </th>
                      <th
                        colSpan={2}
                        style={{ color: "#fff", border: "1px solid #000" }}
                      >
                        ............
                      </th>
                      <th style={{ color: "#fff", border: "1px solid #000" }}>
                        ............
                      </th>
                      <th style={{ color: "#fff", border: "1px solid #000" }}>
                        ............
                      </th>
                    </tr>
                  </tfoot> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ListChecklistsCuratif;
