import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import { Button, Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { PieceRechangeType } from "../../_App/Types/Entites/PieceRechangeType";
import {
  ListPiecesDeRechange,
  savePieceDeRechange,
  updatePieceDeRechange,
} from "../../_App/Redux/Slices/piecesRechange/piecesRechangeSlice";
import { EntretienType } from "../../_App/Types/Entites/EntretienType";
import {
  entretienPieces,
  selectEntretiens,
} from "../../_App/Redux/Slices/entretiens/entretienSlice";
import { generateCode } from "../../_App/Helpers/helpers";
import {
  currentSousSociete,
  OnlineUser,
} from "../../_App/Redux/Slices/auth/authSlice";

const PieceDeRechangeForm = ({
  selectedPiece,
  showModal,
  setShowModal,
  setSelectedPiece,
  load,
  setLoad,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const entretiens = useAppSelector(selectEntretiens);
  const pieces = useAppSelector(ListPiecesDeRechange);
  const currentSousSte = useAppSelector(currentSousSociete);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PieceRechangeType>();

  useEffect(() => {
    if (selectedPiece) {
      reset(selectedPiece);
    } else {
      reset({
        code: generateCode(pieces, "P"),
        libelle: "",
        prix_HT: 0,
        prix_TTC: 0,
        tva: 0,
        remise: 0,
        fournisseur: "",
        sous_societe: currentSousSte,
        stock: 0,
      });
    }
  }, [selectedPiece, showModal]);

  const closeModal = () => {
    if (setSelectedPiece !== null) setSelectedPiece(undefined);
    setShowModal(false);
  };

  const onSubmit: SubmitHandler<PieceRechangeType> = async (data) => {
    if (selectedPiece) {
      try {
        dispatch(updatePieceDeRechange({ id: selectedPiece.id, data: data }));
        setLoad(!load);
        closeModal();
      } catch (error) {
        console.error("Error updating piece de rechange:", error);
      }
    } else {
      let res = await dispatch(savePieceDeRechange(data));
      if (res.payload.success) {
        setLoad(!load);
        closeModal();
      }
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title">
            {selectedPiece
              ? t("Mise à jour de pièce de rechange") + selectedPiece.code
              : t("Ajouter une pièce de rechange")}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            if (setSelectedPiece !== null) setSelectedPiece(undefined);
            setShowModal(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            {" "}
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="libelle">
                  {t("Libellé")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("libelle")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {" "}
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="fournisseur">
                  {t("Fournisseur")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("fournisseur")}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="stock">
                  {t("Stock")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("stock")}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="prix">
                  {t("Prix HT")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("prix_HT")}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="prix">
                  {t("Prix TTC")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("prix_TTC")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="fournisseur">
                  {t("TVA")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("tva")}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="prix">
                  {t("remise")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("remise")}
                />
              </div>
            </div>
          </div>

          <Button variant="primary" type="submit" className="mr-2 float-right">
            {t("Enregistrer")}
          </Button>
          <Button
            variant="secondary"
            className="mr-2 float-right"
            onClick={() => closeModal()}
          >
            {t("Annuler")}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PieceDeRechangeForm;
