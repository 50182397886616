import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../_App/Redux/hooks";
import {
  approveDemande,
  findDemandesEntretien,
  ListDemandesEntretien,
} from "../_App/Redux/Slices/entretiens/entretienSlice";
import { currentSousSociete } from "../_App/Redux/Slices/auth/authSlice";
import Swal from "sweetalert2";
import {
  findVehicules,
  ListVehicules,
  ListVehiculesParCode,
} from "../_App/Redux/Slices/vehicules/vehiculesSlice";

const DemandesEntretiens = () => {
  const dispatch = useAppDispatch();
  const currentSousS = useAppSelector(currentSousSociete);
  const vehicules = useAppSelector(ListVehiculesParCode);
  useEffect(() => {
    if (currentSousS) {
      dispatch(findDemandesEntretien(currentSousS));
      dispatch(findVehicules(currentSousS));
    }
  }, [dispatch, currentSousS]);

  const demandes = useAppSelector(ListDemandesEntretien);

  const approveDemandeModal = (item: any) => {
    Swal.fire({
      title: `Demande ${item.type}`,
      html: `
          <div>
            <p><strong>Chauffeur:</strong> ${item.user_code}</p>
            <p><strong>Description:</strong> ${item.objet}</p>
            <p><strong>Note:</strong> ${item.message}</p>
            <!-- Add more content as needed -->
          </div>
        `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, approuver !",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Approuvée !",
          text: "Demande a été approuvée.",
          icon: "success",
        });
        const res = dispatch(approveDemande(item.id));
      }
    });
  };
  return (
    <div>
      <nav className="navbar search-bar">
        <a href="#" className="sidebar-toggler">
          <i data-feather="menu" />
        </a>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search" />
                </div>
              </div>
              {/* <input
                type="text"
                className="form-control"
                id="navbarForm"
                placeholder="Chercher ..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
              /> */}
            </div>
          </form>
        </div>
      </nav>
      <nav className="page-breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Entretiens</a>
          </li>
        </ol>
      </nav>
      <div className="row content-card">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <i className="mdi mdi-tune-vertical text-primary " /> Demandes
              entretien{" "}
            </div>
            <div className="dropdown-body p-5">
              <ul className="profile-nav p-0 pt-3">
                {demandes &&
                  Object.values(demandes)
                    .flat()
                    .map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <li
                          className="nav-item notification-item mb-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (item.approved === 0) approveDemandeModal(item);
                          }}
                        >
                          <a className="nav-link">
                            <i className="fas fa-tools"></i>{" "}
                            <span
                              style={{
                                textTransform: "capitalize",
                                marginLeft: "10px",
                              }}
                            >
                              {" "}
                              Demande d'entretien de{" "}
                              {vehicules &&
                                vehicules[item.vehicule_code] &&
                                vehicules[item.vehicule_code][0].num_serie}
                            </span>
                            <div style={{ float: "right" }}>
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  marginLeft: "10px",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                }}
                              >
                                {item.date}
                              </span>
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  marginLeft: "10px",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                }}
                              >
                                {item.approved === 1 ? (
                                  <i className="fas fa-check-circle text-success"></i>
                                ) : (
                                  <i className="fas fa-times-circle text-danger"></i>
                                )}
                              </span>
                            </div>
                          </a>
                        </li>
                        {index + 1 < demandes.length ? <hr></hr> : null}
                      </React.Fragment>
                    ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandesEntretiens;
