import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../_App/Redux/hooks";

import UserForm from "../Components/Users/UserForm";

import {
  Auth,
  currentSousSociete,
  findUsers,
  findUserSoussociete,
  OnlineUser,
} from "../_App/Redux/Slices/auth/authSlice";
import UsersList from "../Components/Users/UsersList";
import { findRoles } from "../_App/Redux/Slices/users/userSlice";

const Users = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const currentSousSte = useAppSelector(currentSousSociete);
  const [load, setLoad] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Add state for search term
  const user = useAppSelector(OnlineUser);

  useEffect(() => {
    dispatch(findUsers(null));
    if (user) dispatch(findUserSoussociete(user.code));
    dispatch(findRoles(null));
  }, [dispatch, load, currentSousSte]);

  return (
    <div>
      <nav className="navbar search-bar">
        <a href="#" className="sidebar-toggler">
          <i data-feather="menu" />
        </a>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search" />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                id="navbarForm"
                placeholder="Chercher ..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
              />
            </div>
          </form>
        </div>
      </nav>
      <nav className="page-breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Utilisateurs</a>
          </li>
        </ol>
      </nav>
      <div className="row content-card">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <i className="mdi mdi-tune-vertical text-primary " /> Liste des
              Utilisateurs{" "}
              <button
                className="btn btn-primary float-right"
                onClick={() => setShowModal(true)}
              >
                +
              </button>
            </div>
            <UsersList
              searchTerm={searchTerm} // Pass search term as a prop
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              setShowModal={setShowModal}
              load={load}
              setLoad={setLoad}
            />
            <UserForm
              showModal={showModal}
              setShowModal={setShowModal}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              load={load}
              setLoad={setLoad}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
