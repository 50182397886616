import React from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import { PieceRechangeType } from "../../_App/Types/Entites/PieceRechangeType";
import {
  deletePieceDeRechange,
  ListPiecesDeRechange,
} from "../../_App/Redux/Slices/piecesRechange/piecesRechangeSlice";
import Swal from "sweetalert2";

const PiecesDeRechangeList = ({
  selectedPiece,
  setSelectedPiece,
  setShowModal,
  load,
  setLoad,
  searchTerm,
}: any) => {
  const dispatch = useAppDispatch();
  const piecesDeRechange = useAppSelector(ListPiecesDeRechange);

  const { t } = useTranslation();
  const deletePieceRechange = (item: PieceRechangeType) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Supprimé!",
          text: "Votre fichier a été supprimé.",
          icon: "success",
        });
        const res = dispatch(deletePieceDeRechange(item.id));
        setLoad(!load);
      }
    });
  };
  const filteredList =
    piecesDeRechange &&
    piecesDeRechange.filter((piece) =>
      piece?.libelle?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

  const totalTTC = filteredList.reduce(
    (sum: number, item) => sum + item.prix_TTC,
    0
  );
  const totalHT = filteredList.reduce(
    (sum: number, item) => sum + item.prix_HT,
    0
  );
  return (
    <div className="card-body">
      <table className="table table-bordered">
        <thead>
          <tr className="text-center">
            <th>{t("Libelle")}</th>
            <th>{t("Prix HT")}</th>
            <th>{t("Prix TTC")}</th>
            <th>{t("Fournisseur")}</th>
            <th>{t("Stock")}</th>

            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length &&
            filteredList?.map((item, index) => (
              <tr
                key={index}
                className={
                  index % 2 === 1
                    ? "fs-12 bg-azure-lightest text-center"
                    : "fs-12 text-center"
                }
                onClick={() => setSelectedPiece(item)}
              >
                <td>{item.libelle}</td>
                <td>{item.prix_HT.toFixed(3)}</td>
                <td>{item.prix_TTC.toFixed(3)}</td>
                <td>{item.fournisseur}</td>
                <td className={item.stock > 0 ? "" : "text-danger"}>
                  {item.stock}
                </td>

                <td>
                  <button
                    className="btn btn-info btn-icon"
                    onClick={() => {
                      setSelectedPiece(item);
                      setShowModal(true);
                    }}
                  >
                    <i className="fa fas fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-danger btn-icon"
                    onClick={() => {
                      deletePieceRechange(item);
                    }}
                  >
                    <i className="fa fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr className="text-center">
            <td colSpan={1}>
              <b>{t("Coût total")}</b>
            </td>
            <td className="text-danger">
              <b> {totalHT.toFixed(3)} </b>
            </td>
            <td className="text-danger">
              <b> {totalTTC.toFixed(3)} </b>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default PiecesDeRechangeList;
