import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import {
  checklistsCuratifGroupedByGroupe,
  deleteChecklistItem,
} from "../../_App/Redux/Slices/checklists/checklistsSlice";
import Swal from "sweetalert2";

const Checklists = ({ setShowModal, load, setLoad }: any) => {
  const dispatch = useAppDispatch();
  const checklists = useAppSelector(checklistsCuratifGroupedByGroupe);

  const { t } = useTranslation();

  const deleteItem = (code: any) => {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le !",
    }).then((result) => {
      if (result.isConfirmed) {
        const res = dispatch(deleteChecklistItem(code));
        Swal.fire({
          title: "Supprimé !",
          text: "Votre fichier a été supprimé.",
          icon: "success",
        });
      }
    });
  };
  return (
    <div className="card-body">
      <table
        className="table table-bordered"
        style={{ tableLayout: "fixed", width: "100%" }}
      >
        {Object.entries(checklists).map(([key, value]) => (
          <React.Fragment key={key}>
            <thead>
              <tr onClick={() => console.log(key)}>
                <th
                  colSpan={8}
                  style={{
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {key}
                </th>
              </tr>
            </thead>
            <tbody>
              {value &&
                value.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        cursor: "pointer",
                        maxWidth: "50px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      colSpan={6}
                      style={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordWrap: "break-word",
                      }}
                    >
                      {item.libelle}
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        maxWidth: "50px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      onClick={() => deleteItem(item.code)}
                    >
                      <i
                        className="fas fa-times-circle"
                        style={{ color: "#e7515a" }}
                        onClick={() => console.log(index)}
                      ></i>
                    </td>
                  </tr>
                ))}
            </tbody>
          </React.Fragment>
        ))}
      </table>
    </div>
  );
};

export default Checklists;
