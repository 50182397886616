import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListCamions,
  ListVehicules,
  saveFile,
  saveHistorique,
  saveVehicule,
  updateVehicule,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { toast } from "react-toastify";
import { VehiculeType } from "../../_App/Types/Entites/VehiculeType";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { formadate, generateCode } from "../../_App/Helpers/helpers";
import {
  currentSousSociete,
  OnlineUser,
} from "../../_App/Redux/Slices/auth/authSlice";

const VehiculeForm = ({
  selectedVehicule,
  showModal,
  setShowModal,
  setSelectedVehicule,
  load,
  setLoad,
}: any) => {
  const { t } = useTranslation();
  const camions = useAppSelector(ListCamions);
  const vehicules = useAppSelector(ListVehicules);

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const currentSousSte = useAppSelector(currentSousSociete);

  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<VehiculeType>();

  useEffect(() => {
    if (selectedVehicule) {
      reset(selectedVehicule);
    } else {
      reset({
        code: generateCode(vehicules, "V"),
        code_depot: "",
        marque: "",
        num_serie: "",
        num_chassis: "",
        status: "",
        date_mise_en_circulation: undefined,
        poids_util: 0,
        poids_vide: 0,
        sous_societe: currentSousSte,
      });
    }
  }, [selectedVehicule, showModal]);

  const closeModal = () => {
    if (setSelectedVehicule !== null) setSelectedVehicule(undefined);
    setShowModal(false);
  };

  const [file, setFile] = useState<File | null>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const uploadFile = async () => {
    if (!file) return "";

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}vehicules/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.success) {
        // Assuming `data.filePath` is relative, prepend the base URL
        return `${process.env.REACT_APP_API_URL_UPLOADS + data.filePath}`;
      } else {
        toast.error("File upload failed");
        return "";
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
      return "";
    }
  };

  const onSubmit: SubmitHandler<VehiculeType> = async (data) => {
    const filePath = await uploadFile(); // Await file upload

    if (selectedVehicule) {
      let newData = { ...data };
      newData.date_mise_en_circulation = date;

      try {
        dispatch(updateVehicule({ id: selectedVehicule.id, data: newData }));

        if (data.status !== selectedVehicule.status) {
          dispatch(
            saveHistorique({
              code_vehicule: selectedVehicule.code,
              date: date,
              status: data.status,
              commentaire: "",
              payment: null,
              sous_societe: currentSousSte,
            })
          );
        }
        setLoad(!load);
        closeModal();
      } catch (error) {
        console.error("Error updating vehicule:", error);
      }
    } else {
      let newData = { ...data };
      newData.date_mise_en_circulation = date;

      try {
        let res = await dispatch(saveVehicule(newData));

        if (res.payload.success) {
          let fileData = {
            code: res.payload.data.insertId,
            type: "vehicule_docs",
            path: filePath, // Use the full URL path returned by uploadFile
          };
          dispatch(saveFile(fileData));
          setLoad(!load);
          closeModal();
          toast.success("Vehicule ajoutée avec succes ! ");
        }
      } catch (error) {
        console.error("Error saving vehicule:", error);
      }
    }
  };

  return (
    <Modal show={showModal} onHide={() => closeModal()}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title" id="addCategorieLabel">
            {" "}
            <i className="mdi mdi-plus-circle text-primary mr-1" />{" "}
            {selectedVehicule
              ? t("Mise a jour de vehicule ") + selectedVehicule.num_serie
              : t("Ajouter un véhicule")}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            if (setSelectedVehicule !== null) setSelectedVehicule(undefined);
            setShowModal(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            {!selectedVehicule ? (
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="file">{t("Fichier")}</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={onFileChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="marque">
                    {t("Marque")} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("marque")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>{t("Camion Nomadis")}</label>
                  <select
                    className="form-control w-100 "
                    style={{ height: "40px" }}
                    {...register("code_depot")}
                  >
                    {Object.values(camions)?.map((items, keys) => (
                      <option value={items[0].code} key={keys}>
                        {items[0].code}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="num_serie">
                    {t("Num série")} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("num_serie")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="num_chassis">
                    {t("Num chassis")} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("num_chassis")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="status">
                    {t("Status")} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("status")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label
                    htmlFor="date_mise_en_circulation"
                    onClick={() =>
                      selectedVehicule
                        ? console.log(selectedVehicule.date_mise_en_circulation)
                        : null
                    }
                  >
                    {t("Date mise en circulation")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control text-primary fs-12"
                    value={
                      selectedVehicule
                        ? selectedVehicule.date_mise_en_circulation
                        : date
                    }
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="poids_util">
                    {t("Poids util")} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("poids_util")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="poids_vide">
                    {t("Poids vide")} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("poids_vide")}
                  />
                </div>
              </div>
            </div>
            <Button
              variant="primary"
              type="submit"
              className="mr-2 float-right"
            >
              {t("Enregistrer")}
            </Button>
            <Button
              variant="secondary"
              className="mr-2 float-right"
              onClick={() => closeModal()}
            >
              {t("Annuler")}
            </Button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VehiculeForm;
