import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";

import _ from "lodash";
import {
  AffectationChecklistType,
  ChecklistGroupType,
  ChecklistResultType,
  ChecklistType,
} from "../../../Types/Entites/ChecklistsType";

interface ChecklistData {
  data: any;
  groupe: string;
  id_groupe: string | null;
  maintenance: string;
}

interface data {
  vehiculeCode: string;
  date: string;
}

export const saveChecklistItem = createAsyncThunk(
  "checklists/createChecklist",
  async (data: ChecklistData, { rejectWithValue }) => {
    try {
      const response = await axios.post("checklists/createChecklist", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const saveChecklistGroup = createAsyncThunk(
  "checklists/createGroupChecklist",
  async (data: ChecklistData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "checklists/createGroupChecklist",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const updateResult = createAsyncThunk(
  "checklists/updateResult",
  async ({ id, newData }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `checklists/updateResult/${id}`,
        newData
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findChecklistResult = createAsyncThunk(
  "checklists/findChecklistResult",
  async (machineCode: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `checklists/findChecklistResult/${machineCode}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findChecklistResultByDate = createAsyncThunk(
  "checklists/findChecklistResultByDate",
  async (data: data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `checklists/findChecklistResultByDate/${data.vehiculeCode}/${data.date}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findChecklistResultByPlaning = createAsyncThunk(
  "checklists/findChecklistResultByPlaning",
  async (planingCode: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `checklists/findChecklistResultByPlaning/${planingCode}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findAffectationChecklist = createAsyncThunk(
  "checklists/findAffectationChecklist",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`checklists/findAffectationChecklist`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findAllChecklists = createAsyncThunk(
  "checklists/findChecklists",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`checklists/findChecklists`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteChecklistItem = createAsyncThunk(
  "checklists/deleteChecklist",
  async (code: any, { rejectWithValue, dispatch }) => {
    try {
      console.log(code)
      const response = await axios.delete(`checklists/deleteChecklist/${code}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateChecklistItemApi = createAsyncThunk(
  "checklists/updateChecklistItemApi",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `checklists/updateChecklistItemApi/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findStatusOdf = createAsyncThunk(
  "checklists/findStatus",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`checklists/findStatus`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findChecklistsGroups = createAsyncThunk(
  "checklists/findChecklistsGroups",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`checklists/findChecklistsGroups`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findResultsImages = createAsyncThunk(
  "checklists/findResultsImages",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`checklists/findResultsImages`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface checklist {
  affectationChecklist: { [groupeMachine: string]: AffectationChecklistType[] };
  checklistsCuratifByGroupe: { [groupeChecklist: string]: ChecklistType[] };
  checklistsPreventifByGroupe: { [groupeChecklist: string]: ChecklistType[] };
  groups: ChecklistGroupType[];
  ODFResults: { [checklistCode: string]: ChecklistResultType[] };
  ResultsGroupedByDate: { [date: string]: ChecklistResultType[] };

  PreventifResults: { [checklistCode: string]: ChecklistResultType[] };
  images: any;
}

const initialState: checklist = {
  affectationChecklist: {},
  checklistsCuratifByGroupe: {},
  checklistsPreventifByGroupe: {},
  groups: [],
  ODFResults: {},
  PreventifResults: {},
  ResultsGroupedByDate: {},
  images: {},
};

export const checklistSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveChecklistItem.fulfilled, (state, action) => {
      if (action.payload.data[0]) {
        const data = action.payload.data[0];
        const groupe = data.groupe;

        return {
          ...state,
          checklistsCuratifByGroupe: {
            ...state.checklistsCuratifByGroupe,
            [groupe]: [
              ...(state.checklistsCuratifByGroupe[groupe] || []),
              ...action.payload.data,
            ],
          },
        };
      }
    });

    builder.addCase(findChecklistResult.fulfilled, (state, action) => {
      const groupedByChecklist = _.groupBy(
        action.payload.data,
        "checklist_code"
      );
      const groupedByDate = _.groupBy(action.payload.data, "date");
      return {
        ...state,
        ODFResults: groupedByChecklist,
        ResultsGroupedByDate: groupedByDate,
      };
    });
    builder.addCase(findChecklistResultByDate.fulfilled, (state, action) => {
      const groupedByChecklist = _.groupBy(
        action.payload.data,
        "checklist_code"
      );
      const groupedByDate = _.groupBy(action.payload.data, "date");
      return {
        ...state,
        ODFResults: groupedByChecklist,
      };
    });
    builder.addCase(findChecklistResultByPlaning.fulfilled, (state, action) => {
      const groupedByChecklist = _.groupBy(action.payload, "checklist_code");
      return {
        ...state,
        PreventifResults: groupedByChecklist,
      };
    });
    builder.addCase(deleteChecklistItem.fulfilled, (state, action) => {});

    builder.addCase(findAllChecklists.fulfilled, (state, action) => {
      console.log(action.payload);
      const groupedByMaintenance = _.groupBy(
        action.payload.data,
        "maintenance"
      );

      const curatifGroupedByGroupe = _.groupBy(
        groupedByMaintenance["Curatif"],
        "groupe"
      );
      const preventifGroupedByGroupe = _.groupBy(
        groupedByMaintenance["Préventif"],
        "groupe"
      );
      return {
        ...state,
        checklistsCuratifByGroupe: curatifGroupedByGroupe,
        checklistsPreventifByGroupe: preventifGroupedByGroupe,
      };
    });
    builder.addCase(findAffectationChecklist.fulfilled, (state, action) => {
      const groupedByMachines = _.groupBy(
        action.payload.data,
        "groupe_machine"
      );
      return {
        ...state,
        affectationChecklist: groupedByMachines,
      };
    });
    builder.addCase(findStatusOdf.fulfilled, (state, action) => {
      const groupedByOdf = _.groupBy(action.payload, "odf_code");

      return {
        ...state,
        statusByOdf: groupedByOdf,
      };
    });
    builder.addCase(findChecklistsGroups.fulfilled, (state, action) => {
      return {
        ...state,
        groups: action.payload,
      };
    });
    builder.addCase(updateChecklistItemApi.fulfilled, (state, action) => {
      console.log(action.payload);

      // Return original state if index is not found
      return state;
    });
    builder.addCase(findResultsImages.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload.data, "code");
      return {
        ...state,
        images: groupedByCode,
      };
    });
  },
});

export const affectationChecklistByMachineGroup = (state: RootState) =>
  state.checklist.affectationChecklist;
export const checklistsCuratifGroupedByGroupe = (state: RootState) =>
  state.checklist.checklistsCuratifByGroupe;
export const checklistsPreventifGroupedByGroupe = (state: RootState) =>
  state.checklist.checklistsPreventifByGroupe;
export const Groups = (state: RootState) => state.checklist.groups;
export const ChecklistResults = (state: RootState) =>
  state.checklist.ODFResults;

export const PreventifChecklistResults = (state: RootState) =>
  state.checklist.PreventifResults;
export const ResultsByDate = (state: RootState) =>
  state.checklist.ResultsGroupedByDate;
export const ResultsImages = (state: RootState) => state.checklist.images;

export default checklistSlice.reducer;
