import { AssuranceType } from "../Types/Entites/AssuranceType";

export const calculTTc = (prixht: number, tva: number) => {
  return prixht + (prixht * tva) / 100;
};

export const calculHt = (prixttc: number, tva: number) => {
  return (100 * prixttc) / (100 + tva);
};

export const calculTTC_Remise = (
  prix_ttc: number,
  quantite: number,
  remise: number
) => {
  const totalBeforeRemise = quantite * prix_ttc;
  const remiseAmount = (totalBeforeRemise * remise) / 100;
  const totalAfterRemise = totalBeforeRemise - remiseAmount;

  const round = (value: number, decimalPlaces: number): number => {
    const factor = 10 ** decimalPlaces;
    return Math.round(value * factor) / factor;
  };

  return round(totalAfterRemise, 3);
};

export const formadate = (date: any) => {
  const inputDate = new Date(date);

  const day = inputDate.getDate().toString().padStart(2, "0");
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDate.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const formatdate = (date: any) => {
  const inputDate = new Date(date);

  const day = inputDate.getDate().toString().padStart(2, "0");
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDate.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
export const generateRandomEAN = (length: any) => {
  const randomDigits = Array.from({ length }, () =>
    Math.floor(Math.random() * 10)
  ).join("");
  return calculateEANCheckDigit(randomDigits, length);
};

const calculateEANCheckDigit = (digits: any, length: any) => {
  if (digits.length !== length) {
    throw new Error(`Invalid number of digits for EAN-${length}`);
  }

  let sum = 0;
  for (let i = 0; i < digits.length; i++) {
    const digit = parseInt(digits[i], 10);
    sum += i % 2 === 0 ? digit : digit * 3;
  }

  const checkDigit = (10 - (sum % 10)) % 10;

  return `${digits}${checkDigit}`;
};
export const areArraysEqual = (arr1: any, arr2: any) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};
export const handleKeyPress = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const charCode = event.which || event.keyCode;

  // Allow only numeric characters (0-9), period (.), and minus sign (-)
  if ((charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
    event.preventDefault();
  }

  // Ensure that only one period is allowed
  const inputText = event.currentTarget.value;
  if (charCode === 46 && inputText.indexOf(".") !== -1) {
    event.preventDefault();
  }

  // Ensure that only one minus sign is allowed at the beginning
  if (charCode === 45 && inputText.indexOf("-") !== -1) {
    event.preventDefault();
  }
};
export const formatDateAndTime = (timestamp: Date) => {
  const dateObject = new Date(timestamp);

  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");

  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");
  const seconds = dateObject.getSeconds().toString().padStart(2, "0");

  const formattedDateAndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateAndTime;
};

export const formatTime = ({ date }: { date: string }) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Date(date).toLocaleTimeString(undefined, options);
};

export const duration = (date: string) => {
  const itemDate: Date = new Date(date);
  const today: Date = new Date();

  // Difference in milliseconds
  const timeDiff: number = today.getTime() - itemDate.getTime();

  // Convert milliseconds to days
  const daysDiff: number = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  // Calculate years, months, and remaining days
  const years = Math.floor(daysDiff / 365);
  const months = Math.floor((daysDiff % 365) / 30); // Approximating months as 30 days
  const remainingDays = daysDiff % 30; // Remaining days after removing years and months

  return { years, months, days: remainingDays };
};

export const filterObjectsWithDate30DaysLater = (objectsArray: any) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set time to midnight
  return objectsArray.filter((obj: AssuranceType) => {
    if (obj.date_fin) {
      const objDate = new Date(obj.date_fin);
      objDate.setHours(0, 0, 0, 0); // Set time to midnight
      const differenceInMs: number = objDate.getTime() - currentDate.getTime();
      const differenceInDays: number = Math.round(
        differenceInMs / (1000 * 60 * 60 * 24)
      );
      return differenceInDays <= 30 && differenceInDays >= 0; // Adjusted condition
    } else {
      return false; // or true, depending on your requirements
    }
  });
};

export function numberWithSpaces(x: any) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function generateCode(list: any, prefix: string): string {
  let uniqueId: string;
  const existingOrderNumbers = list.map((item: any) => item.code);
  do {
    uniqueId = prefix + (Math.floor(Math.random() * 90000) + 10000).toString();
  } while (
    existingOrderNumbers.some((orderNumber: any) => orderNumber === uniqueId)
  );

  return uniqueId;
}

export function generateMultipleCodes(
  list: any,
  prefix: string,
  count: number
): string[] {
  const generatedCodes: string[] = [];

  for (let i = 0; i < count; i++) {
    let newCode = generateCode(
      [...list, ...generatedCodes.map((code) => ({ code }))],
      prefix
    );
    generatedCodes.push(newCode);
  }

  return generatedCodes;
}

export function getNextYearDate(date: Date) {
  let nextYearDate = new Date(date);
  nextYearDate.setFullYear(date.getFullYear() + 1);
  nextYearDate.setDate(nextYearDate.getDate() - 1);
  return nextYearDate;
}

export function convertVehicleStatusToDataset(vehicleStatus: any) {
  const monthKeys = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const monthNames: any = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };

  const convertedDataset = monthKeys.map((monthKey) => {
    const statuses = vehicleStatus[monthKey] || {};

    return {
      "en panne": statuses["en panne"] ? statuses["en panne"].length : 0,
      "en marche": statuses["en marche"] ? statuses["en marche"].length : 0,
      "au parking": statuses["au parking"] ? statuses["au parking"].length : 0,
      "en reparation": statuses["en reparation"]
        ? statuses["en reparation"].length
        : 0,
      month: monthNames[monthKey],
    };
  });

  return convertedDataset;
}
