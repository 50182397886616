import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { PayHistoryType } from "../../_App/Types/Entites/PayHistoryType";
import { savePayHistory } from "../../_App/Redux/Slices/payHistory/payHistorySlice";
import { toast } from "react-toastify";
import { AssuranceType } from "../../_App/Types/Entites/AssuranceType";
import { getNextYearDate } from "../../_App/Helpers/helpers";
import { updateAssurance } from "../../_App/Redux/Slices/assurances/assurancesSlice";
import { updateVisiteTechnique } from "../../_App/Redux/Slices/visiteTech/visiteTechSlice";
import { updateTaxe } from "../../_App/Redux/Slices/taxes/taxesSlice";
import { currentSousSociete } from "../../_App/Redux/Slices/auth/authSlice";
import {
  ListVehiculesParCode,
  ListZones,
  saveHistorique,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";

const PayAssuranceForm = ({
  showModal,
  setShowModal,
  toPay,
  setToPay,
  type,
  setType,
  load,
  setLoad,
}: any) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const currentSousste = useAppSelector(currentSousSociete);
  const zones = useAppSelector(ListZones);
  const vehicules = useAppSelector(ListVehiculesParCode);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PayHistoryType>();
  const [cout, setCout] = useState<any>();

  useEffect(() => {
    reset({
      date: date,
      cout: toPay?.montant_TTC,
      paid_id: toPay?.id,
      vehicule_code: toPay?.vehicule_code,
      type: type,
      sous_societe: currentSousste,
    });
  }, [showModal]);

  const closeModal = () => {
    setType(undefined);
    setToPay(undefined);
    setShowModal(false);
  };

  const onSubmit: SubmitHandler<PayHistoryType> = async (data) => {
    data.sous_societe = "2";
    let res = await dispatch(savePayHistory(data));
    if (res.payload.success) {
      dispatch(
        saveHistorique({
          code_vehicule: toPay?.vehicule_code,
          date: date,
          status: vehicules[toPay?.vehicule_code][0].status,
          commentaire: "",
          payment: res.payload.data.insertId,
          sous_societe: currentSousste,
        })
      );
    }

    if (res.payload.success) {
      if (type === "assurance") {
        let resUpdate = await dispatch(
          updateAssurance({
            id: toPay.id,
            data: {
              date: toPay.date_fin,
              date_fin: moment(
                getNextYearDate(new Date(toPay.date_fin))
              ).format("YYYY-MM-DD"),
              montant_TTC: cout,
              montant_HT: cout,
            },
          })
        );
      } else if (type === "visite") {
        let resUpdate = await dispatch(
          updateVisiteTechnique({
            id: toPay.id,
            data: {
              date_visite: toPay.date_fin,
              date_fin: moment(
                getNextYearDate(new Date(toPay.date_fin))
              ).format("YYYY-MM-DD"),
              montant_TTC: cout,
              montant_HT: cout,
            },
          })
        );
      } else if (type === "taxe") {
        let resUpdate = await dispatch(
          updateTaxe({
            id: toPay.id,
            data: {
              date_visite: toPay.date_fin,
              date_fin: moment(
                getNextYearDate(new Date(toPay.date_fin))
              ).format("YYYY-MM-DD"),
              montant_TTC: cout,
              montant_HT: cout,
            },
          })
        );
      }
      setLoad(!load);
      closeModal();
      toast.success("Payée ! ");
    }
  };
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title" id="addVisiteTechniqueLabel">
            <i className="mdi mdi-plus-circle text-primary mr-1" />
            {type === "assurance"
              ? t("Paie assurance")
              : type === "visite"
              ? t("Paie visite technique")
              : t("Paie taxe")}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            closeModal();
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="status">{t("Coût")}</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("cout")}
                    onChange={(e) => setCout(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="user_id">
                    {t("Zone")}
                    <span className="text-danger">*</span>
                  </label>
                  <select className="form-control" {...register("zone")}>
                    {zones?.map((zone: any) => (
                      <option key={zone.code} value={zone.code}>
                        {zone.libelle}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="mr-2 float-right"
            >
              {t("Enregistrer")}
            </Button>
            <Button
              variant="secondary"
              className="mr-2 float-right"
              onClick={() => closeModal()}
            >
              {t("Annuler")}
            </Button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PayAssuranceForm;
