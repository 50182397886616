import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import { VisiteTechType } from "../../../Types/Entites/VisiteTechType";
import { filterObjectsWithDate30DaysLater } from "../../../Helpers/helpers";

export const saveVisiteTechnique = createAsyncThunk(
  "visiteTech/createVisiteTechnique",
  async (data: VisiteTechType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "visiteTech/createVisiteTechnique",
        data
      ); // Adjust the API endpoint accordingly
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findVisiteTechniques = createAsyncThunk(
  "visiteTech/findVisiteTechniques",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`visiteTech/findVisiteTechniques/${id}`); // Adjust the API endpoint accordingly
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateVisiteTechnique = createAsyncThunk(
  "visiteTech/updateVisiteTechnique",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `visiteTech/updateVisiteTechnique/${id}`,
        data
      ); // Adjust the API endpoint accordingly
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteVisiteTechnique = createAsyncThunk(
  "visiteTech/deleteVisiteTechnique",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `visiteTech/deleteVisiteTechnique/${id}`
      ); // Adjust the API endpoint accordingly
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface VisiteTechniques {
  visiteTechniqueList: VisiteTechType[]; // Adjust the type accordingly
  monthAwayVisiteByVehicule: { [code: string]: VisiteTechType[] };
}

const initialState: VisiteTechniques = {
  visiteTechniqueList: [],
  monthAwayVisiteByVehicule: {},
};

export const visiteTechniqueSlice = createSlice({
  name: "visiteTechnique",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveVisiteTechnique.fulfilled, (state, action) => {
      let newVisiteTechnique = action.payload.data;
      state.visiteTechniqueList.splice(0, 0, newVisiteTechnique);
    });

    builder.addCase(findVisiteTechniques.fulfilled, (state, action) => {
      const filteredVisites = filterObjectsWithDate30DaysLater(
        action.payload.data
      );
      const monthAwaygroupedByVehicule = _.groupBy(
        filteredVisites,
        "vehicule_code"
      );
      return {
        ...state,
        visiteTechniqueList: action.payload.data,
        monthAwayVisiteByVehicule: monthAwaygroupedByVehicule,
      };
    });

    builder.addCase(updateVisiteTechnique.fulfilled, (state, action) => {
      const index = _.findIndex(state.visiteTechniqueList, {
        id: action.payload.data.id,
      });
      if (index !== -1) {
        const updatedList = [...state.visiteTechniqueList];
        updatedList[index] = {
          ...updatedList[index],
          // Assuming you want to update other properties as well
          // e.g., updatedList[index].property = action.payload.data.property
        };
        return {
          ...state,
          visiteTechniqueList: updatedList,
        };
      }

      return state;
    });
  },
});

export const listVisiteTechniques = (state: RootState) =>
  state.visiteTechnique.visiteTechniqueList;
export const ListMonthAwayVisiteTech = (state: RootState) =>
  state.visiteTechnique.monthAwayVisiteByVehicule;

export default visiteTechniqueSlice.reducer;
