import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import moment from "moment";
import { ListUsers } from "../../_App/Redux/Slices/users/userSlice";
import VehiculeUserAffectationForm from "../Vehicules/AffectVehiculeToUserForm";

const UsersList = ({
  selectedUser,
  setSelectedUser,
  setShowModal,
  load,
  setLoad,
  searchTerm,
}: any) => {
  const users = useAppSelector(ListUsers);

  const deleteUserById = (item: any) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Supprimé!",
          text: "Votre fichier a été supprimé.",
          icon: "success",
        });
        // const res = dispatch(deleteUserById(item.id)); gonna desactivate user here not delete it
        setLoad(!load);
      }
    });
  };

  const filteredList = users.filter((user) => {
    const nomMatches = user.nom
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());
    const prenomMatches = user.prenom
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());
    const roleMatches = user.role_code
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    return nomMatches || prenomMatches || roleMatches;
  });

  const { t } = useTranslation();

  const [showModalAffectation, setShowModalAffectation] = useState(false);
  const [user, setUser] = useState<string | undefined>(undefined);

  return (
    <div className="card-body">
      <VehiculeUserAffectationForm
        vehicule={null}
        setVehicule={null}
        user={user}
        setUser={setUser}
        load={load}
        setLoad={setLoad}
        showModal={showModalAffectation}
        setShowModal={setShowModalAffectation}
      />
      <table className="table table-bordered">
        <thead>
          <tr className="text-center">
            <th>{t("Nom")}</th>
            <th>{t("Code de rôle")}</th>
            <th>{t("mobile")}</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length &&
            filteredList.map((item, keys) => (
              <tr
                key={keys}
                className={
                  keys % 2 === 1
                    ? "fs-12 bg-azure-lightest text-center"
                    : "fs-12 text-center"
                }
              >
                <td>
                  <b className="text-muted">{item.nom + " " + item.prenom}</b>
                </td>
                <td>{item.role_code}</td>

                <td>{item.mobile}</td>
                <td>
                  {/* <button
                    className="btn btn-primary btn-icon"
                    onClick={() => {
                      setSelectedUser(item);
                      setShowModalAffectation(true);
                    }}
                  >
                    <i className="fas fa-truck"></i>{" "}
                  </button> */}
                  <button
                    className="btn btn-info btn-icon"
                    onClick={() => {
                      setSelectedUser(item);
                      setShowModal(true);
                    }}
                  >
                    <i className="fa fas fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-danger btn-icon"
                    onClick={() => {
                      deleteUserById(item);
                    }}
                  >
                    <i className="fa fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersList;
