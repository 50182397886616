import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import { EntretienType } from "../../_App/Types/Entites/EntretienType";
import {
  deleteEntretien,
  selectEntretiens,
} from "../../_App/Redux/Slices/entretiens/entretienSlice";
import Swal from "sweetalert2";
import {
  ListVehiculesParCode,
  ListZonesByCode,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { ListPayHistory } from "../../_App/Redux/Slices/payHistory/payHistorySlice";
import { PayHistoryType } from "../../_App/Types/Entites/PayHistoryType";
import moment from "moment";
import { fr } from "date-fns/locale";

const ListPayhistory = ({ searchTerm }: any) => {
  const dispatch = useAppDispatch();
  const payHistory = useAppSelector(ListPayHistory);
  const vehicules = useAppSelector(ListVehiculesParCode);
  const zones = useAppSelector(ListZonesByCode);

  const { t } = useTranslation();

  const deleteAnEntretien = (item: EntretienType) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Supprimé!",
          text: "Votre fichier a été supprimé.",
          icon: "success",
        });
        dispatch(deleteEntretien(item.id));
      }
    });
  };

  const [startDate, setStartDate] = useState<any>(moment().startOf("month")); // Start of current month
  const [endDate, setEndDate] = useState<any>(moment().endOf("month")); // End of current month

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setStartDate(moment(date).startOf("day"));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setEndDate(moment(date).endOf("day"));
    }
  };

  const filteredList = payHistory.filter((item) => {
    const vehicule = vehicules[item.vehicule_code];
    const vehiculeMatches =
      vehicule &&
      (vehicule[0].marque + " " + vehicule[0].num_serie)
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

    const categorieMatches = item.type
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    const itemDate = moment(item.date, "YYYY-MM-DD");
    const dateMatches =
      itemDate.isSameOrAfter(startDate) && itemDate.isSameOrBefore(endDate);

    return (vehiculeMatches || categorieMatches) && dateMatches;
  });

  const total = filteredList.reduce((sum: number, item) => sum + item.cout, 0);
  const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
    <input
      className="date-input"
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
    />
  ));
  return (
    <div className="card-body">
      <div className="date-filter" style={{ display: "flex", float: "right" }}>
        <div
          className="pb-2 mr-5"
          style={{ display: "flex", alignItems: "center" }}
        >
          <DatePicker
            selected={startDate.toDate()}
            onChange={handleStartDateChange}
            dateFormat="yyyy-MM-dd"
            className="date-input"
            locale={fr}
            customInput={<CustomInput />}
          />
        </div>
        <div className="pb-2" style={{ display: "flex", alignItems: "center" }}>
          <DatePicker
            selected={endDate.toDate()}
            onChange={handleEndDateChange}
            dateFormat="yyyy-MM-dd"
            className="date-input"
            locale={fr}
            customInput={<CustomInput />}
          />
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr className="text-center">
            <th>{t("Vehicule")}</th>
            <th>{t("Date")}</th>
            <th>{t("Coût")}</th>
            <th>{t("Sous societé")}</th>
            <th>{t("Zone")}</th>
            <th>{t("Type")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length &&
            filteredList?.map((item: PayHistoryType, index: number) => (
              <tr
                key={index}
                className={
                  index % 2 === 1
                    ? "fs-12 bg-azure-lightest text-center"
                    : "fs-12 text-center"
                }
              >
                <td>
                  <b className="text-muted">
                    {vehicules[item.vehicule_code] &&
                    vehicules[item.vehicule_code][0]
                      ? vehicules[item.vehicule_code][0].marque +
                        " " +
                        vehicules[item.vehicule_code][0].num_serie
                      : item.vehicule_code}
                  </b>
                </td>
                <td>{item.date}</td>
                <td>{item.cout.toFixed(3)}</td>
                <td>{item.society_name}</td>
                <td>
                  {zones && zones[item.zone] && zones[item.zone][0]
                    ? zones[item.zone][0].libelle
                    : item.zone}
                </td>
                <td>{item.type}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr className="text-center">
            <td colSpan={2}>
              <b>{t("Coût total")}</b>
            </td>
            <td className="text-danger">
              <b> {total.toFixed(3)} </b>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ListPayhistory;
