import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { useAppSelector } from "../../_App/Redux/hooks";
import { HistoriqueVehicules } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";

const chartSetting = {
//   yAxis: [
//     {
//       label: "rainfall (mm)",
//     },
//   ],
  width: 500,
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-20px, 0)",
    },
  },
};

const valueFormatter = (value: number | null) => `${value} Vehicules`;

export default function LinesDataset({ data }: any) {
  console.log(data);
  return (
    <LineChart
      dataset={data}
      xAxis={[{ scaleType: "band", dataKey: "month" }]}
      series={[
        { dataKey: "en panne", label: "En panne", valueFormatter },
        { dataKey: "en marche", label: "En marche", valueFormatter },
        { dataKey: "au parking", label: "Au parking", valueFormatter },
        { dataKey: "en reparation", label: "En reparation", valueFormatter },
      ]}
      {...chartSetting}
    />
  );
}
