import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Button, Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { EntretienType } from "../../_App/Types/Entites/EntretienType";
import {
  entretienPieces,
  saveEntretien,
  saveEntretienPIeces,
  updateEntretien,
} from "../../_App/Redux/Slices/entretiens/entretienSlice";
import { ListVehicules } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import AffectationPieceForm from "./AffectationPieceForm";
import {
  ListPiecesDeRechange,
  savePieceDeRechangeList,
  updatePieceDeRechangesStock,
} from "../../_App/Redux/Slices/piecesRechange/piecesRechangeSlice";
import {
  currentSousSociete,
  OnlineUser,
} from "../../_App/Redux/Slices/auth/authSlice";
import NewPieceDeRechange from "./NewPiecesForm";
import { generateMultipleCodes } from "../../_App/Helpers/helpers";

type Data = {
  id: number | null;
  piece: string;
  quantity: number;
  prix: number;
  stock?: number;
};
type DataNewPieces = {
  code?: string;
  libelle: string;
  fournisseur: string;
  prix_TTC: number;
  prix_HT: number;
  tva: number;
  stock?: number;
  remise: number;
  sous_societe?: string;
};
type StockUpdateData = {
  id: string;
  quantity: number;
};

const EntretienForm = ({
  selectedEntretien,
  showModal,
  setShowModal,
  setSelectedEntretien,
  load,
  setLoad,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [Data, setData] = useState<Data[]>([]);
  const [dataNewPieces, setDataNewPieces] = useState<DataNewPieces[]>();

  const [totalPiecePrice, setTotalPiecePrice] = useState(0);
  const vehicules = useAppSelector(ListVehicules);
  const entrtienPieces = useAppSelector(entretienPieces);
  const currentSousSte = useAppSelector(currentSousSociete);
  const pieces = useAppSelector(ListPiecesDeRechange);

  let allData = Data;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<EntretienType>();

  const cout_pieces = watch("cout_pieces", 0);

  useEffect(() => {
    if (selectedEntretien) {
      reset(selectedEntretien);
      const piecesPrice =
        entrtienPieces &&
        entrtienPieces[selectedEntretien.id] &&
        entrtienPieces[selectedEntretien.id].reduce(
          (sum: number, piece: any) => sum + piece.prix * piece.quantity,
          0
        );
      setTotalPiecePrice(piecesPrice);
    } else {
      reset({
        vehicule_code: "",
        date: "",
        type: "",
        montant_TTC: 0,
        montant_HT: 0,
        kilometrage: 0,
        piece_jointe: "",
        commentaire: "",
        cout_pieces: 0,
        cout_total: 0,
        sous_societe: currentSousSte,
      });
    }
  }, [selectedEntretien, reset]);

  useEffect(() => {
    setValue("cout_total", cout_pieces + totalPiecePrice);
  }, [cout_pieces, totalPiecePrice, setValue]);

  const closeModal = () => {
    if (setSelectedEntretien !== null) setSelectedEntretien(undefined);
    setShowModal(false);
  };

  const onSubmit: SubmitHandler<EntretienType> = async (data) => {
    if (selectedEntretien) {
      try {
        // dispatch(updateEntretien({ id: selectedEntretien.id, data: data }));
        setLoad(!load);
        closeModal();
      } catch (error) {
        console.error("Error updating entretien:", error);
      }
    } else {
      let res = await dispatch(saveEntretien(data));
      if (dataNewPieces) {
        let aux = [...dataNewPieces]; // Ensure it's a new array
        let codes = generateMultipleCodes(pieces, "P", aux.length);

        aux.forEach((item, index) => {
          item.code = codes[index];
          item.sous_societe = currentSousSte;
        });

        try {
          const res = await dispatch(savePieceDeRechangeList(aux));
          if (res.payload.success) {
            res.payload.data.map((item: any) => {
              allData.push({
                piece: item.code,
                quantity: item.stock,
                id: null,
                prix: item.prix_TTC,
                stock: item.stock,
              });
            });
          }
          // handle the response if needed
        } catch (error) {
          // handle the error
          console.error("Error saving pieces:", error);
        }
      }

      if (res.payload.success) {
        if (allData && allData.length) {
          let res2 = await dispatch(
            saveEntretienPIeces({
              entretien_id: res.payload.data.insertId,
              data: allData,
            })
          );
        }
        let items: StockUpdateData[] = [];
        allData &&
          allData.map((item) =>
            items.push({ id: item.piece, quantity: item.quantity })
          );
        dispatch(updatePieceDeRechangesStock(items));
        setLoad(!load);
        closeModal();
      }
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} size="xl" scrollable>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title" onClick={() => console.log(allData)}>
            {selectedEntretien
              ? t("Mise à jour entretien")
              : t("Ajouter un entretien")}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            if (setSelectedEntretien !== null) setSelectedEntretien(undefined);
            setShowModal(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="vehicule_code">
                  {t("Vehicule")}
                  <span className="text-danger">*</span>
                </label>
                <select className="form-control" {...register("vehicule_code")}>
                  {vehicules.map((vehicule: any, index: number) => (
                    <option key={index} value={vehicule.code}>
                      {vehicule.marque + " " + vehicule.num_serie}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="date">
                  {t("Date")} <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  {...register("date")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="vehicule_code">
                  {t("Type")}
                  <span className="text-danger">*</span>
                </label>
                <select className="form-control" {...register("type")}>
                  <option value={"vidange"}>Vidange</option>
                  <option value={"lavage"}>Lavage</option>
                  <option value={"entretien general"}>Entretien general</option>
                  <option value={"sinistre"}>Sinistre</option>
                </select>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="km_debut">
                  {t("Kilometrage")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("kilometrage")}
                />
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="montant_HT">
                  {t("Montant HT")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("montant_HT")}
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label htmlFor="montant_TTC">
                  {t("Montant TTC")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("montant_TTC")}
                />
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="pieces">
                  {t("Coût de la main-d'œuvre")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("cout_pieces", { valueAsNumber: true })}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="total">
                  {t("Coût total")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={cout_pieces + totalPiecePrice}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="commentaire">{t("Commentaire")}</label>
            <textarea
              className="form-control"
              rows={3}
              {...register("commentaire")}
            ></textarea>
          </div>
          <AffectationPieceForm
            setData={setData}
            entretien={selectedEntretien?.id}
            onTotalPriceChange={setTotalPiecePrice}
          />
          {!selectedEntretien ? (
            <NewPieceDeRechange
              setData={setDataNewPieces}
              entretien={selectedEntretien?.id}
              onTotalPriceChange={setTotalPiecePrice}
            />
          ) : null}
          <div className="mt-3 mb-5">
            {" "}
            <Button
              variant="primary"
              type="submit"
              className="mr-2 float-right"
            >
              {t("Enregistrer")}
            </Button>
            <Button
              variant="secondary"
              className="mr-2 float-right"
              onClick={() => closeModal()}
            >
              {t("Annuler")}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EntretienForm;
