import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../_App/Redux/hooks";
import { findAllChecklists } from "../_App/Redux/Slices/checklists/checklistsSlice";
import Checklists from "../Components/Checklists/Checklists";
import ChecklistForm from "../Components/Checklists/ChecklistForm";

const ChecklistsPage = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    dispatch(findAllChecklists(null));
  }, [dispatch, load]);

  return (
    <div>
      <nav className="page-breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Checklists</a>
          </li>
        </ol>
      </nav>
      <div className="row content-card">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <i className="mdi mdi-tune-vertical text-primary " />
              <button
                className="btn btn-primary float-right"
                onClick={() => setShowModal(true)}
              >
                +
              </button>
            </div>
            <Checklists
              setShowModal={setShowModal}
              load={load}
              setLoad={setLoad}
            />
            <ChecklistForm
              showModal={showModal}
              setShowModal={setShowModal}
              load={load}
              setLoad={setLoad}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistsPage;
