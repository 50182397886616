import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import { filterObjectsWithDate30DaysLater } from "../../../Helpers/helpers";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TaxeType } from "../../../Types/Entites/TaxesType";

export const saveTaxe = createAsyncThunk(
  "taxes/createTaxe",
  async (data: TaxeType, { rejectWithValue }) => {
    try {
      const response = await axios.post("taxes/createTaxe", data); // Adjust the API endpoint accordingly
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findTaxes = createAsyncThunk(
  "taxes/findTaxes",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`taxes/findTaxes/${id}`); // Adjust the API endpoint accordingly
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateTaxe = createAsyncThunk(
  "taxes/updateTaxe",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`taxes/updateTaxe/${id}`, data); // Adjust the API endpoint accordingly
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteTaxe = createAsyncThunk(
  "taxes/deleteTaxe",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`taxes/deleteTaxe/${id}`); // Adjust the API endpoint accordingly
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Taxes {
  TaxeList: TaxeType[]; // Adjust the type accordingly
  monthAwayVisiteByVehicule: { [code: string]: TaxeType[] };
}

const initialState: Taxes = {
  TaxeList: [],
  monthAwayVisiteByVehicule: {},
};

export const TaxeSlice = createSlice({
  name: "Taxe",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveTaxe.fulfilled, (state, action) => {
      let newTaxe = action.payload.data;
      state.TaxeList.splice(0, 0, newTaxe);
    });

    builder.addCase(findTaxes.fulfilled, (state, action) => {
      const filteredVisites = filterObjectsWithDate30DaysLater(
        action.payload.data
      );
      const monthAwaygroupedByVehicule = _.groupBy(
        filteredVisites,
        "vehicule_code"
      );
      return {
        ...state,
        TaxeList: action.payload.data,
        monthAwayVisiteByVehicule: monthAwaygroupedByVehicule,
      };
    });

    builder.addCase(updateTaxe.fulfilled, (state, action) => {
      const index = _.findIndex(state.TaxeList, {
        id: action.payload.data.id,
      });
      if (index !== -1) {
        const updatedList = [...state.TaxeList];
        updatedList[index] = {
          ...updatedList[index],
          // Assuming you want to update other properties as well
          // e.g., updatedList[index].property = action.payload.data.property
        };
        return {
          ...state,
          TaxeList: updatedList,
        };
      }

      return state;
    });
  },
});

export const listTaxes = (state: RootState) => state.taxes.TaxeList;
export const ListMonthAwaytaxes = (state: RootState) =>
  state.taxes.monthAwayVisiteByVehicule;

export default TaxeSlice.reducer;
