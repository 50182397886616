import React from "react";
import { numberWithSpaces } from "../../_App/Helpers/helpers";
import { useAppSelector } from "../../_App/Redux/hooks";
import { Top10Camions } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";

const DAshboardTable = ({ t }: any) => {
  const topcamions = useAppSelector(Top10Camions);
  return (
    <div className="col-lg-12 col-xl-12 stretch-card">
      {/* <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-baseline mb-3">
            <h6 className=" mb-3">
              {" "}
              <i className="mdi mdi-trophy-outline ml-2 text-primary" /> Top 10
              Camions (Selon le Chiffre d'affaire){" "}
            </h6>
          </div>
          <div className="table-responsive ">
            <table className="table table-hover  mb-0">
              <thead>
                <tr className=" fs-10 text-center">
                  <th>{t("parametrage.code")}</th>
                  <th>{t("parametrage.libelle")}</th>
                  <th>{t("parametrage.type")}</th>
                  <th>{t("ca")}</th>
                  <th>{t("chargevariable")}</th>
                  <th>{t("chargesFixes")}</th>
                  <th>{t("fiche")}</th>
                </tr>
              </thead>
              <tbody>
                {topcamions?.map((items: any, keys: any) => (
                  <tr
                    key={keys}
                    className={
                      keys % 2 === 1
                        ? "fs-12 bg-azure-lightest text-center"
                        : "fs-12 text-center"
                    }
                  >
                    <td>{items.code}</td>
                    <td>{items.libelle}</td>
                    <td>{items.type}</td>
                    <td>
                      {" "}
                      <b className="text-muted">
                        {items.net_a_payer === null
                          ? 0
                          : numberWithSpaces(
                              parseFloat(items.net_a_payer).toFixed(3)
                            )}
                      </b>
                    </td>
                    <td>
                      <b className="text-muted">
                        {items.charges_fixes === null
                          ? 0
                          : parseFloat(items.charges_fixes).toFixed(3)}
                      </b>
                    </td>
                    <td>
                      <b className="text-muted">
                        {items.charges_variables === null
                          ? 0
                          : parseFloat(items.charges_variables).toFixed(3)}
                      </b>
                    </td>
                    <td>
                      {" "}
                      <a href={"/GestionCamion/FicheCamion&code=" + items.code}>
                        {" "}
                        <i
                          className="mdi mdi-format-list-bulleted text-primary"
                          style={{ cursor: "pointer" }}
                        />
                      </a>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DAshboardTable;
