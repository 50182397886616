import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { login } from "../_App/Redux/Slices/auth/authSlice";
import { useAppDispatch } from "../_App/Redux/hooks";

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const [t, i18n] = useTranslation("global");
  //-------- FORMULAIRE D AUTHENTIFICATION
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // -------------- Loading -----------
  const [loading, setLoading] = useState(false);

  const authenfication = async () => {
    /**
     * Authentification par E-Mail /
     */
    if (email !== "" && password !== "") {
      setLoading(true);
      let res = await dispatch(login({ email, password }));
      console.log("res", res);
      if (res.payload.token && res.payload.token) {
        toast(t("login.messagesuccee"), {
          type: "success",
          position: "top-right",
        });
        window.location.href = "/";
      } else if (res.payload.success === false) {
        toast(t("login.messageerror"), {
          type: "error",
          position: "top-right",
        });
      }
    }
  };
  return (
    <div className="main-wrapper">
      <div className="page-wrapper full-page">
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-8 col-xl-6 mx-auto">
              <div className="card">
                <div className="row">
                  <div className="col-md-4 pr-md-0">
                    <div className="auth-left-wrapper">
                      <ToastContainer />
                    </div>
                  </div>
                  <div className="col-md-8 pl-md-0">
                    <div className="auth-form-wrapper px-4 py-5">
                      <a href="#" className="noble-ui-logo d-block mb-2">
                        Noma<span>Truck</span>
                      </a>
                      <h5 className="text-muted font-weight-normal mb-4">
                        Welcome back! Log in to your account.
                      </h5>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder={t("login.email")}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder={t("login.pwd")}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block"
                          onClick={() => authenfication()}
                        >
                          {t("login.seconnecter")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
