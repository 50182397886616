import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { getStats, stats } from "../../_App/Redux/Slices/stats/statsSlice";
import { soussocietes } from "../../_App/Redux/Slices/auth/authSlice";
import _ from "lodash";
import BasicPie from "./Pie";
import BasicBars from "./Bars";
import { HistoriqueVehicules } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { convertVehicleStatusToDataset } from "../../_App/Helpers/helpers";
import SimpleLineChart from "./Lines";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { fr } from "date-fns/locale";
import { frCA } from "date-fns/locale";

interface statsItem {
  sous_societe: number;
  total_assurance: number;
  total_taxe: number;
  total_visite: number;
  total_entretien: number;
  total_en_panne: number;
  total_en_marche: number;
  total_en_reparation: number;
  total_au_parking: number;
}
const AnalysesCards = () => {
  const { t } = useTranslation();
  const costsStats = useAppSelector(stats);
  const sousSociete = useAppSelector(soussocietes);
  const sousSocieteByCode: any = _.groupBy(sousSociete, "code");
  const history = useAppSelector(HistoriqueVehicules);
  var pieData: any[] = [];
  // Initialize totals
  let totalEnPanne = 0;
  let totalEnMarche = 0;
  let totalEnReparation = 0;
  let totalAuParking = 0;
  let totalAssurances = 0;
  let totalVisitesTechniques = 0;
  let totalEntretiens = 0;
  let totalAutres = 0;
  let totalOverallCosts = 0;

  // Calculate totals
  if (Array.isArray(costsStats) && costsStats.length > 0) {
    costsStats.forEach((item: statsItem) => {
      totalEnPanne += item.total_en_panne || 0;
      totalEnMarche += item.total_en_marche || 0;
      totalEnReparation += item.total_en_reparation || 0;
      totalAuParking += item.total_au_parking || 0;

      totalAssurances += item.total_assurance || 0;
      totalVisitesTechniques += item.total_visite || 0;
      totalEntretiens += item.total_entretien || 0;
      totalAutres += item.total_taxe || 0;

      totalOverallCosts +=
        (item.total_assurance || 0) +
        (item.total_visite || 0) +
        (item.total_entretien || 0) +
        (item.total_taxe || 0);
    });
  }

  const [startDate, setStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  ); // Start of current month
  const [endDate, setEndDate] = useState<any>(
    moment().endOf("month").format("YYYY-MM-DD")
  ); // End of current month

  const dispatch = useAppDispatch();
  useEffect(() => {
    console.log(startDate, endDate);
    dispatch(getStats({ start: startDate, end: endDate }));
  }, [startDate, endDate]);

  const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
    <input
      className="date-input"
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
    />
  ));

  return (
    <div>
      <div className="row content-card">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6
                  className="mb-0 text-primary"
                  onClick={() =>
                    console.log(convertVehicleStatusToDataset(history))
                  }
                >
                  Les sous-sociétés
                </h6>
              </div>
              <div className="table-responsive ">
                <div
                  className="date-filter mt-2"
                  style={{ display: "flex", float: "right" }}
                >
                  <div
                    className="pb-2 mr-5"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DatePicker
                      selected={startDate}
                      onChange={(date) =>
                        setStartDate(date?.toISOString().split("T")[0])
                      }
                      dateFormat="yyyy-MM-dd"
                      className="date-input"
                      locale={fr}
                      customInput={<CustomInput />}
                    />
                  </div>
                  <div
                    className="pb-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DatePicker
                      selected={endDate}
                      onChange={(date) =>
                        setEndDate(date?.toISOString().split("T")[0])
                      }
                      dateFormat="yyyy-MM-dd"
                      className="date-input"
                      locale={fr}
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>
                <table className="table table-hover mb-0 stats-tab">
                  <thead onClick={() => console.log(pieData)}>
                    <tr className="fs-10">
                      <th className="single">{t("Sous société")}</th>
                      <th
                        colSpan={4}
                        style={{
                          borderRight: "2px solid #e8ebf1",
                          borderLeft: "2px solid #e8ebf1",
                        }}
                      >
                        {t("Nombre de véhicules")}
                      </th>
                      <th
                        colSpan={4}
                        style={{ borderRight: "2px solid #e8ebf1" }}
                      >
                        {t("Coûts")}
                      </th>

                      <th className="single">{t("Total")}</th>
                    </tr>
                    <tr className="fs-10">
                      <th></th>
                      <th
                        style={{
                          borderLeft: "2px solid #e8ebf1",
                        }}
                      >
                        {t("En panne")}
                      </th>
                      <th>{t("En marche")}</th>
                      <th>{t("En réparation")}</th>
                      <th
                        style={{
                          borderRight: "2px solid #e8ebf1",
                        }}
                      >
                        {t("Au parking")}
                      </th>
                      <th>{t("assurances")}</th>
                      <th>{t("visites techniques")}</th>
                      <th>{t("entretiens")}</th>
                      <th style={{ borderRight: "2px solid #e8ebf1" }}>
                        {t("Autres")}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(costsStats) && costsStats.length > 0 ? (
                      costsStats.map((item: statsItem, key) => {
                        // Calculate total costs for the current row
                        const totalCosts =
                          (item.total_assurance || 0) +
                          (item.total_visite || 0) +
                          (item.total_entretien || 0) +
                          (item.total_taxe || 0);

                        pieData.push({
                          id: item.sous_societe,
                          value: totalCosts,
                          label: sousSocieteByCode[item.sous_societe]?.[0]?.nom,
                        });

                        return (
                          <tr
                            key={item.sous_societe} // Assuming sous_societe is unique
                            className={`fs-12 ${
                              key % 2 === 1 ? "bg-azure-lightest" : ""
                            }`}
                          >
                            <td>
                              {sousSocieteByCode[item.sous_societe]?.[0]?.nom ||
                                "N/A"}
                            </td>
                            <td className="text-primary">
                              {item.total_en_panne || 0}
                            </td>
                            <td className="text-primary">
                              {item.total_en_marche || 0}
                            </td>
                            <td className="text-primary">
                              {item.total_en_reparation || 0}
                            </td>
                            <td className="text-primary">
                              {item.total_au_parking || 0}
                            </td>
                            <td className="text-primary">
                              {item.total_assurance || 0}
                            </td>
                            <td className="text-primary">
                              {item.total_visite || 0}
                            </td>
                            <td className="text-primary">
                              {item.total_entretien || 0}
                            </td>
                            <td className="text-primary">
                              {item.total_taxe || 0}
                            </td>
                            <td className="text-primary">{totalCosts}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10}>No data available</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr className="fs-12">
                      <td>
                        <b>{t("Total")}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalEnPanne}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalEnMarche}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalEnReparation}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalAuParking}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalAssurances}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalVisitesTechniques}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalEntretiens}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalAutres}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalOverallCosts}</b>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="row content-card">
        <div className="col-6 mb-3">
          <div className="card" style={{ height: "100%" }}>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6 className="mb-0 text-primary">
                  Coûts totaux des sous-sociétés{" "}
                </h6>
              </div>
              <div
                className="table-responsive"
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BasicPie data={pieData} />
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="col-6 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6 className="mb-0 text-primary">
                  {" "}
                  Status des vehicules {moment().year()}
                </h6>
              </div>
              <div className="table-responsive ">
                {/* <BasicBars data={convertVehicleStatusToDataset(history)} /> */}
                <SimpleLineChart
                  data={convertVehicleStatusToDataset(history)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row content-card">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6 className="mb-0 text-primary">
                  Coûts totaux des sous-sociétés par mois{" "}
                </h6>
              </div>
              <div className="table-responsive ">
                <BarsDataset data={pieData} />
              </div>
            </div>
          </div>
        </div>{" "}
      </div> */}
    </div>
  );
};

export default AnalysesCards;
