import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  deleteVehicule,
  ListAffectations,
  ListCamions,
  ListVehicules,
  ListZonesByCode,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { useTranslation } from "react-i18next";
import { numberWithSpaces } from "../../_App/Helpers/helpers";
import VehiculeUserAffectationForm from "./AffectVehiculeToUserForm";
import { usersByCode } from "../../_App/Redux/Slices/auth/authSlice";
import { VehiculeType } from "../../_App/Types/Entites/VehiculeType";
import Swal from "sweetalert2";
import DateModal from "./ChecklistsDates";
import {
  findChecklistResult,
  ResultsByDate,
} from "../../_App/Redux/Slices/checklists/checklistsSlice";
import VehiculeUserHistoryModal from "./VehiculeUserHistoryModal";

const VehiculesList = ({
  selectedVehicule,
  setSelectedVehicule,
  setShowModal,
  load,
  setLoad,
  searchTerm,
}: any) => {
  const dispatch = useAppDispatch();
  const vehicules = useAppSelector(ListVehicules);
  const camions = useAppSelector(ListCamions);
  const affectations = useAppSelector(ListAffectations);
  const users = useAppSelector(usersByCode);
  const zones = useAppSelector(ListZonesByCode);
  const [showModalHistory, setShowModalHistory] = useState(false);
  const [vehicule, setVehicule] = useState<string | undefined>(undefined);

  const [showModalAffectation, setShowModalAffectation] = useState(false);
  const [showModalDates, setShowModalDates] = useState(false);
  const [vehiculeCode, setVehiculeCode] = useState<string | undefined>(
    undefined
  );

  const { t } = useTranslation();

  const deletevehicule = (item: VehiculeType) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Supprimé!",
          text: "Votre fichier a été supprimé.",
          icon: "success",
        });
        dispatch(deleteVehicule(item.id));
        setLoad(!load);
      }
    });
  };

  // Filter vehicules based on the search term
  const filteredList = vehicules.filter(
    (vehicule) =>
      (vehicule.marque + " " + vehicule.num_serie)
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      vehicule.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (vehiculeCode) dispatch(findChecklistResult(vehiculeCode));
  }, [dispatch, vehiculeCode]);

  const dates = useAppSelector(ResultsByDate);

  return (
    <div className="card-body">
      <VehiculeUserAffectationForm
        vehicule={selectedVehicule}
        setVehicule={setSelectedVehicule}
        user={null}
        setUser={null}
        load={load}
        setLoad={setLoad}
        showModal={showModalAffectation}
        setShowModal={setShowModalAffectation}
      />
      <VehiculeUserHistoryModal
        showModal={showModalHistory}
        setShowModal={setShowModalHistory}
        vehicule={vehicule}
        setVehicule={setVehicule}
      />
      <DateModal
        showModalDates={showModalDates}
        setShowModalDates={setShowModalDates}
        vehiculeCode={vehiculeCode}
        setVehiculeCode={setVehiculeCode}
      />

      <table className="table table-bordered ">
        <thead>
          <tr className="text-center">
            <th>{t("Libelle")}</th>
            {/* <th>{t("Libelle")}</th> */}
            <th>{t("Marque")}</th>
            <th>{t("Chauffeur")}</th>
            <th>{t("Status")}</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length > 0 ? (
            filteredList.map((item, keys) => (
              <tr
                key={keys}
                className={
                  keys % 2 === 1
                    ? "fs-12 bg-azure-lightest text-center"
                    : "fs-12 text-center"
                }
              >
                <td
                  onClick={() => {
                    setVehiculeCode(item.code);
                    setShowModalDates(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <b className="text-muted">
                    {item.code_depot &&
                    Object.keys(camions).includes(item.code_depot) ? (
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/nomadis.png"
                        }
                        alt="nomadis"
                        style={{ borderRadius: 0 }}
                      />
                    ) : null}
                    {item.marque + " " + item.num_serie}{" "}
                  </b>
                </td>
                {/* <td>
                  {item.code_depot && camions[item.code_depot]
                    ? camions[item.code_depot][0].libelle
                    : null}
                </td> */}
                <td>{item.marque} </td>
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {affectations &&
                  affectations[item.code] &&
                  zones &&
                  zones[affectations[item.code][0]?.zone] &&
                  Object.keys(affectations).includes(item.code) ? (
                    <div>
                      {users[affectations[item.code][0]?.user_id][0]?.nom +
                        " " +
                        users[affectations[item.code][0]?.user_id][0]?.prenom +
                        " - "}{" "}
                      {zones[affectations[item.code][0]?.zone][0]?.libelle}{" "}
                      <i className="fas fa-map-marker-alt"></i>
                      <p className="mt-2">
                        <div id="app" className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="swiper-container">
                                <div
                                  className="swiper-wrapper"
                                  style={{ display: "inline-flex" }}
                                >
                                  <div className="swiper-slide">
                                    <div className="status">
                                      <span>
                                        <b>
                                          {
                                            affectations[item.code][0]
                                              .date_debut
                                          }
                                        </b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="swiper-slide">
                                    <div className="status">
                                      <span>
                                        <b>
                                          {affectations[item.code][0].date_fin}
                                        </b>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </p>
                    </div>
                  ) : null}{" "}
                  <button
                    className="btn btn-primary btn-icon"
                    onClick={() => {
                      setVehicule(item.code);
                      setShowModalHistory(true);
                    }}
                  >
                    <i className="fas fa-history"></i>
                  </button>
                </td>
                <td>
                  <div
                    className="badge"
                    style={{
                      background:
                        item.status === "en panne"
                          ? "#e60039"
                          : item.status === "en reparation"
                          ? "#fbbc06"
                          : "#7bd0a0",
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.status}
                  </div>{" "}
                </td>

                <td>
                  {" "}
                  <button
                    className="btn btn-primary btn-icon"
                    onClick={() => {
                      setSelectedVehicule(item);
                      setShowModalAffectation(true);
                    }}
                  >
                    <i className="fa fas fa-user"></i>
                  </button>
                  <button
                    className="btn btn-info btn-icon"
                    onClick={() => {
                      setSelectedVehicule(item);
                      setShowModal(true);
                    }}
                  >
                    {" "}
                    <i className="fa fas fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-secondary btn-icon"
                    onClick={() =>
                      (window.location.href = `/vehiculehistory/${item.code}`)
                    }
                  >
                    <i className="fas fa-external-link-alt"></i>{" "}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className="text-center">
                {t("No results found")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default VehiculesList;
