import React, { useState } from "react";

const ZoomableImage = ({ imageUrl, size }: any) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomClick = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", textAlign: "center" }}
    >
      <div
        style={{
          width: size, // Adjust square size as needed
          height: size, // Adjust square size as needed
          overflow: "hidden",
          cursor: "pointer",
          position: "relative",
          backgroundImage: `url(${imageUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover", // Contain the image when zoomed, cover it otherwise
          backgroundRepeat: "no-repeat",
          transition: "width 0.5s, height 0.5s, background-size 0.5s", // Smooth transition
          borderRadius: "10px",
          boxShadow: "0px 1px 9px 0px #000000",
        }}
        onClick={handleZoomClick}
      ></div>
      {isZoomed && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)", // Semi-transparent black overlay
            zIndex: 9999, // Ensure it's above other content
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={handleZoomClick}
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              border: "none",
              background: "none",
              color: "#fff",
            }}
          >
            <i className="fas fa-times"></i>
          </button>
          <img
            className="zoomable-image"
            src={imageUrl}
            alt="Zoomable"
            style={{
              maxWidth: "90%", // Adjust as needed
              maxHeight: "90%", // Adjust as needed
              objectFit: "contain",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ZoomableImage;
