import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { ListVehicules } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import {
  saveAssurance,
  updateAssurance,
} from "../../_App/Redux/Slices/assurances/assurancesSlice";
import { toast } from "react-toastify";
import { AssuranceType } from "../../_App/Types/Entites/AssuranceType";
import {
  currentSousSociete,
  OnlineUser,
} from "../../_App/Redux/Slices/auth/authSlice";

const AssuranceForm = ({
  selectedAssurance,
  showModal,
  setShowModal,
  setSelectedAssurance,
  load,
  setLoad,
}: any) => {
  const { t } = useTranslation();
  const vehicules = useAppSelector(ListVehicules); // Assuming you have a slice for vehicules in Redux
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const currentSousSte = useAppSelector(currentSousSociete);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AssuranceType>();

  useEffect(() => {
    if (selectedAssurance) {
      reset(selectedAssurance);
    } else {
      reset({
        vehicule_code: "",
        date: "",
        date_fin: "",
        num_contrat: "",
        montant_TTC: 0,
        montant_HT: 0,
        echeance: "",
        categorie: "",
        piece_jointe: "",
        sous_societe: currentSousSte,
      });
    }
  }, [selectedAssurance, showModal]);

  const closeModal = () => {
    if (setSelectedAssurance !== null) setSelectedAssurance(undefined);
    setShowModal(false);
  };

  const onSubmit: SubmitHandler<AssuranceType> = async (data) => {
    if (selectedAssurance) {
      // let newData = { ...data };
      // newData.date_mise_en_circulation = date;
      try {
        dispatch(updateAssurance({ id: selectedAssurance.id, data: data }));
        setLoad(!load);
        closeModal();
      } catch (error) {
        console.error("Error updating assurance:", error);
      }
    } else {
      let res = await dispatch(saveAssurance(data));
      if (res.payload.success) {
        setLoad(!load);
        closeModal();
        toast.success("Vehicule ajoutée avec succes ! ");
      }
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title" id="addAssuranceLabel">
            <i className="mdi mdi-plus-circle text-primary mr-1" />
            {selectedAssurance
              ? t("MAJ Assurance")
              : t("Nouvelle Assurance")}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            if (setSelectedAssurance !== null) setSelectedAssurance(undefined);
            setShowModal(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="vehicule_code">
                    {t("Vehicule")}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    {...register("vehicule_code")}
                  >
                    {vehicules.map((vehicule: any) => (
                      <option key={vehicule.code} value={vehicule.code}>
                        {vehicule.num_serie}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="date">
                    {t("Date")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("date")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="date_fin">
                    {t("Date Fin")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("date_fin")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="num_contrat">
                    {t("Num Contrat")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("num_contrat")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="montant_TTC">
                    {t("Montant TTC")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("montant_TTC")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="montant_HT">
                    {t("Montant HT")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("montant_HT")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="echeance">
                    {t("Echeance")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("echeance")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="categorie">
                    {t("Categorie")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("categorie")}
                  />
                </div>
              </div>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="mr-2 float-right"
            >
              {t("Enregistrer")}
            </Button>
            <Button
              variant="secondary"
              className="mr-2 float-right"
              onClick={() => closeModal()}
            >
              {t("Annuler")}
            </Button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssuranceForm;
