import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  deleteTaxe,
  listTaxes,
} from "../../_App/Redux/Slices/taxes/taxesSlice";
import { TaxeType } from "../../_App/Types/Entites/TaxesType";
import Swal from "sweetalert2";
import { ListVehiculesParCode } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";

const TaxesList = ({
  selectedTaxe,
  setSelectedTaxe,
  setShowModal,
  load,
  setLoad,
  searchTerm,
}: any) => {
  const taxes = useAppSelector(listTaxes);
  const vehicules = useAppSelector(ListVehiculesParCode);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const deleteTaxes = (item: TaxeType) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Supprimé!",
          text: "Votre fichier a été supprimé.",
          icon: "success",
        });
        const res = dispatch(deleteTaxe(item.id));
        setLoad(!load);
      }
    });
  };
  const filteredList = taxes.filter(
    (taxe) =>
      vehicules[taxe.vehicule_code] &&
      (
        vehicules[taxe.vehicule_code][0].marque +
        " " +
        vehicules[taxe.vehicule_code][0].num_serie
      )
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  const total = filteredList.reduce((sum: number, item) => sum + item.montant_TTC, 0);
  return (
    <div className="card-body">
      <table className="table table-bordered ">
        <thead>
          <tr className="text-center">
            <th>{t("Vehicule")}</th>
            <th>{t("Date début")}</th>
            <th>{t("Date fin")}</th>
            <th>{t("Coût")}</th>
            <th>{t("status")}</th>
            <th>{t("motif")}</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length &&
            filteredList?.map((item, keys) => (
              <tr
                key={keys}
                className={
                  keys % 2 === 1
                    ? "fs-12 bg-azure-lightest text-center"
                    : "fs-12 text-center"
                }
                onClick={() => setSelectedTaxe(item)}
              >
                <td>
                  <b className="text-muted">
                    {vehicules[item.vehicule_code] &&
                    vehicules[item.vehicule_code][0]
                      ? vehicules[item.vehicule_code][0].marque +
                        " " +
                        vehicules[item.vehicule_code][0].num_serie
                      : item.vehicule_code}
                  </b>
                </td>
                <td>{item.date_visite}</td>
                <td>{item.date_fin}</td>
                <td>{item.montant_TTC.toFixed(3)}</td>

                <td>{item.status}</td>
                <td>{item.motif}</td>

                <td>
                  <button
                    className="btn btn-info btn-icon"
                    onClick={() => {
                      setSelectedTaxe(item);
                      setShowModal(true);
                    }}
                  >
                    <i className="fa fas fa-edit"></i>
                  </button>
                  {/* Add delete functionality if needed */}
                  <button
                    className="btn btn-danger btn-icon"
                    onClick={() => deleteTaxes(item)}
                  >
                    <i className="fa fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr className="text-center">
            <td colSpan={3}>
              <b>{t("Coût total")}</b>
            </td>
            <td className="text-danger">
              <b> {total.toFixed(3)} </b>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TaxesList;
