import axios from "axios";
import { useAppDispatch, useAppSelector } from "./_App/Redux/hooks";
import { Auth, findUserSoussociete } from "./_App/Redux/Slices/auth/authSlice";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FirstPage from "./Pages/DashboardPages/Dashboard";
import Layout from "./_Layouts/Layout";
import LoginPage from "./Pages/LoginPage";
import Vehicules from "./Pages/Vehicules";
import Assurances from "./Pages/Assurances";
import VisiteTechnique from "./Pages/VisiteTech";
import PiecesRechange from "./Pages/PiecesRechange";
import Entretiens from "./Pages/Entretien";
import "./App.css";
import ChecklistsPage from "./Pages/ChecklistsPage";
import Taxes from "./Pages/Taxes";
import ListChecklistsCuratif from "./Components/Checklists/Result";
import ChecklistsResultPage from "./Pages/ResultPage";
import History from "./Pages/HistoriquePayPage";
import Analyses from "./Pages/DashboardPages/Analyses";
import VehiculeHistory from "./Components/Vehicules/VehiculeHistory";
import Users from "./Pages/Users";
import DemandesEntretiens from "./Pages/DemandesEntretiens";

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const userOnline = useAppSelector(Auth);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (userOnline && userOnline.token) {
      dispatch(findUserSoussociete(userOnline.user.code));
    } else if (!userOnline && window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  }, [userOnline]);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {userOnline && userOnline.token && userOnline.user ? (
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<FirstPage />} />
              <Route path="/analyses" element={<Analyses />} />
              <Route path="/vehicules" element={<Vehicules />} />
              <Route path="/demandes" element={<DemandesEntretiens />} />

              <Route
                path="/vehiculeHistory/:vehicule"
                element={<VehiculeHistory />}
              />
              <Route
                path="/users"
                element={<Users />}
              />
              <Route path="/taxes" element={<Taxes />} />
              <Route path="/assurances" element={<Assurances />} />
              <Route path="/visitesTech" element={<VisiteTechnique />} />
              <Route path="/piecesRechange" element={<PiecesRechange />} />
              <Route path="/entretiens" element={<Entretiens />} />{" "}
              <Route path="/checklists" element={<ChecklistsPage />} />
              <Route path="/history" element={<History />} />
              <Route
                path="/result/:vehiculeCode/:date"
                element={<ChecklistsResultPage />}
              />
            </Route>
          ) : (
            <Route path="/login" element={<LoginPage />} />
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
