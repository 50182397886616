import React from "react";
import SideBar from "../Shared/SideBar";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const Layout = () => {
  return (
    <div className="main-wrapper">
      <ToastContainer />

      <SideBar />
      <div className="page-wrapper">
        <Header />
        <div className="page-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
