import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import { Button, Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { AffectationUserVehiculeType } from "../../_App/Types/Entites/VehiculeType";
import {
  affectVehiculeToUser,
  ListAffectations,
  ListZones,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { users } from "../../_App/Redux/Slices/auth/authSlice";

const VehiculeUserAffectationForm = ({
  showModal,
  setShowModal,
  load,
  setLoad,
  vehicule,
  setVehicule,
  user,
  setUser,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const usersList = useAppSelector(users);
  const zones = useAppSelector(ListZones);
  const affectations = useAppSelector(ListAffectations);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AffectationUserVehiculeType>();

  useEffect(() => {
    reset({
      user_id: user ? user : 0,
      vehicule_code: vehicule ? vehicule.code : "",
      date_debut: "",
      date_fin: "",
      klm_debut: 0,
      klm_fin: null,
      zone: "",
    });
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
    setVehicule(undefined);
  };

  const onSubmit: SubmitHandler<AffectationUserVehiculeType> = async (data) => {
    data.vehicule_code = vehicule.code;
    console.log(Object.keys(affectations));
    const res = await dispatch(affectVehiculeToUser(data));
    if (res.payload.success) {
      setLoad(!load);
      closeModal();
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title">
            {t("Ajouter une affectation du véhicule")}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            closeModal();
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="user_id">
                  {t("Chauffeur")}
                  <span className="text-danger">*</span>
                </label>
                <select className="form-control" {...register("user_id")}>
                  {usersList?.map((user: any) => (
                    <option key={user.code} value={user.code}>
                      {user.nom + " " + user.prenom}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="date_debut">
                  {t("Date de début")} <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  {...register("date_debut")}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="date_fin">{t("Date de fin")}</label>
                <input
                  type="date"
                  className="form-control"
                  {...register("date_fin")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="date_debut">
                  {t("Kilometrage début")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("klm_debut")}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="user_id">
                  {t("Zone")}
                  <span className="text-danger">*</span>
                </label>
                <select className="form-control" {...register("zone")}>
                  {zones?.map((zone: any) => (
                    <option key={zone.code} value={zone.code}>
                      {zone.libelle}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <Button variant="primary" type="submit" className="mr-2 float-right">
            {t("Enregistrer")}
          </Button>
          <Button
            variant="secondary"
            className="mr-2 float-right"
            onClick={() => closeModal()}
          >
            {t("Annuler")}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default VehiculeUserAffectationForm;
