import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import {
  EntretienPiecesType,
  EntretienType,
} from "../../../Types/Entites/EntretienType";
import _ from "lodash";

export const saveEntretien = createAsyncThunk(
  "entretien/createEntretien",
  async (data: EntretienType, { rejectWithValue }) => {
    try {
      const response = await axios.post("entretien/createEntretien", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const saveEntretienPIeces = createAsyncThunk(
  "entretien/createEntretienPieces",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "entretien/createEntretienPieces",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const findEntretienPieces = createAsyncThunk(
  "entretien/findPiecesEntretien",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`entretien/findPiecesEntretien`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findEntretiens = createAsyncThunk(
  "entretien/findEntretiens",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`entretien/findEntretiens/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findDemandesEntretien = createAsyncThunk(
  "entretien/findDemandesEntretien",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`entretien/findDemandesEntretien/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findLastMonthCost = createAsyncThunk(
  "entretien/findLastMonthCost",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`entretien/findLastMonthCost`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateEntretien = createAsyncThunk(
  "entretien/updateEntretien",
  async ({ id, data }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`entretien/updateEntretien/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const approveDemande = createAsyncThunk(
  "entretien/approveDemande",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`entretien/approveDemande/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteEntretien = createAsyncThunk(
  "entretien/deleteEntretien",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`entretien/deleteEntretien/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteEntretienPiece = createAsyncThunk(
  "entretien/deleteEntretienPiece",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `entretien/deleteEntretienPiece/${id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface EntretiensState {
  entretiensList: EntretienType[];
  entretiensPieces: { [entretien: string]: EntretienPiecesType[] };
  demandesEntretien: { [approved: string]: any[] };
}

const initialState: EntretiensState = {
  entretiensList: [],
  entretiensPieces: {},
  demandesEntretien: {},
};

export const entretiensSlice = createSlice({
  name: "entretiens",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveEntretien.fulfilled, (state, action) => {
      let newEntretien = action.payload.data;
      state.entretiensList.splice(0, 0, newEntretien);
    });

    builder.addCase(findEntretiens.fulfilled, (state, action) => {
      return {
        ...state,
        entretiensList: action.payload.data,
      };
    });

    builder.addCase(findEntretienPieces.fulfilled, (state, action) => {
      const groupedByEntretien = _.groupBy(action.payload.data, "entretien_id");

      return {
        ...state,
        entretiensPieces: groupedByEntretien,
      };
    });
    builder.addCase(findDemandesEntretien.fulfilled, (state, action) => {
      const grouped = _.groupBy(action.payload.data, "approved");
      console.log(grouped)

      return {
        ...state,
        demandesEntretien: grouped,
      };
    });
    builder.addCase(updateEntretien.fulfilled, (state, action) => {
      const index = state.entretiensList.findIndex(
        (entretien) => entretien.id === action.payload.data.id
      );
      if (index !== -1) {
        const updatedList = [...state.entretiensList];
        updatedList[index] = {
          ...updatedList[index],
          ...action.payload.data,
        };
        return {
          ...state,
          entretiensList: updatedList,
        };
      }
      return state;
    });
  },
});

export const selectEntretiens = (state: RootState) =>
  state.entretiens.entretiensList;
export const entretienPieces = (state: RootState) =>
  state.entretiens.entretiensPieces;
export const ListDemandesEntretien = (state: RootState) =>
  state.entretiens.demandesEntretien;

export default entretiensSlice.reducer;
