import React, { useEffect } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findChecklistResult,
  ResultsByDate,
} from "../../_App/Redux/Slices/checklists/checklistsSlice";

const DateModal = ({
  showModalDates,
  setShowModalDates,
  vehiculeCode,
  setVehiculeCode,
}: any) => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    setVehiculeCode(undefined);
    setShowModalDates(false);
  };
  useEffect(() => {
    if (vehiculeCode) dispatch(findChecklistResult(vehiculeCode));
  }, [dispatch, vehiculeCode]);

  const dates = useAppSelector(ResultsByDate);
  return (
    <div>
      <Modal show={showModalDates} onHide={() => closeModal()}>
        <Modal.Header>
          <Modal.Title>
            <span className="modal-title" id="addCategorieLabel">
              {" "}
              Choisir date{" "}
            </span>
          </Modal.Title>
          <button
            type="reset"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              closeModal();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {Object.keys(dates).map((date, index) => (
              <ListGroup.Item
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() =>
                  (window.location.href =
                    "/result/" + vehiculeCode + "/" + date)
                }
              >
                {date}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DateModal;
