import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

type Data = {
  libelle: string;
  fournisseur: string;
  prix_TTC: number;
  prix_HT: number;
  tva: number;
  stock: number;
  remise: number;
};

interface AffectationPieceFormProps {
  setData: (data: Data[]) => void;
  entretien: number | null;
  onTotalPriceChange: (totalPrice: number) => void;
}

const NewPieceDeRechange: React.FC<AffectationPieceFormProps> = ({
  setData,
  entretien,
  onTotalPriceChange,
}) => {
  const [rows, setRows] = useState<Data[]>([]);

  const addRow = () => {
    const newRow: Data = {
      libelle: "",
      fournisseur: "",
      prix_HT: 0,
      prix_TTC: 0,
      stock: 1,
      remise: 0,
      tva: 19,
    };
    setRows([...rows, newRow]);
  };

  const deleteRow = (indexToDelete: number) => {
    const updatedRows = rows.filter((_, index) => index !== indexToDelete);
    setRows(updatedRows);
  };

  const updateRow = (index: number, updatedRow: Partial<Data>) => {
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], ...updatedRow };
    setRows(updatedRows);
  };

  useEffect(() => {
    setData(rows);
    const totalPrice = rows.reduce(
      (sum, row) => sum + row.prix_TTC * row.stock,
      0
    );
    onTotalPriceChange(totalPrice);
  }, [rows, setData, onTotalPriceChange]);

  return (
    <div>
      <div className="searchable-container m-0">
        <div className="widget-content searchable-container list">
          <div className="searchable-items list">
            <h6 className="mb-3">Achat pièces de rechange</h6>
            <form>
              {rows.length > 0 && (
                <div className="items">
                  <div
                    className="item-content"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <div className="col-3 pieces">
                      <b>Libellé</b>
                    </div>
                    <div className="col-3 pieces">
                      <b>Fournisseur</b>
                    </div>
                    <div className="col-1 pieces">
                      <b>Prix HT</b>
                    </div>
                    <div className="col-1 pieces">
                      <b>Prix TTC</b>
                    </div>
                    <div className="col-1 pieces">
                      <b>QTE</b>
                    </div>
                    <div className="col-1 pieces">
                      <b>Remise</b>
                    </div>
                    <div className="col-1 pieces">
                      <b>tva</b>
                    </div>
                    <div className="col-1 piecess"></div>
                  </div>
                </div>
              )}

              {rows.map((row, index) => (
                <div className="items" key={index}>
                  <div
                    className="item-content"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <div className="col-3 pieces">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Libellé"
                        value={row.libelle}
                        onChange={(e) =>
                          updateRow(index, { libelle: e.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-3 pieces">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fournisseur"
                        value={row.fournisseur}
                        onChange={(e) =>
                          updateRow(index, {
                            fournisseur: e.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-1 pieces">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Prix HT"
                        value={row.prix_HT}
                        onChange={(e) =>
                          updateRow(index, { prix_HT: +e.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-1 pieces">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Prix TTC"
                        value={row.prix_TTC}
                        onChange={(e) =>
                          updateRow(index, { prix_TTC: +e.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-1 pieces">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Stock"
                        value={row.stock}
                        onChange={(e) =>
                          updateRow(index, { stock: +e.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-1 pieces">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Remise"
                        value={row.remise}
                        onChange={(e) =>
                          updateRow(index, { remise: +e.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-1 pieces">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="tva"
                        value={row.tva}
                        onChange={(e) =>
                          updateRow(index, { tva: +e.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-1 piecess">
                      <i
                        className="fas fa-times-circle"
                        style={{ color: "#e7515a" }}
                        onClick={() => deleteRow(index)}
                      ></i>
                    </div>
                  </div>
                </div>
              ))}

              <button
                className="btn btn-success mb-3"
                style={{ width: "100%" }}
                onClick={addRow}
                type="button"
              >
                <i className="fas fa-plus"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPieceDeRechange;
